import React from 'react';
import styled, { useTheme } from 'styled-components';
import { Stack } from '@tymate/margaret';
import { RawLink } from '@tymate/elise/ui';
import { FiArrowUpRight } from 'react-icons/fi';

export const PrimaryLink = styled(RawLink)`
  color: ${({ theme }) => theme.primary};
  text-decoration: none;
`;

const ExternalLink = ({ children, url }) => {
  const theme = useTheme();

  return (
    <PrimaryLink as="a" href={url} target="_blank" rel="noopener noreferer">
      <Stack alignY="center" gutterSize={0.25}>
        <div>{children}</div>
        <FiArrowUpRight color={theme.primary} />
      </Stack>
    </PrimaryLink>
  );
};

export default ExternalLink;
