import {
  add,
  format,
  startOfDay,
  startOfMonth,
  addSeconds,
  endOfDay,
  addHours,
  areIntervalsOverlapping,
  isBefore,
} from 'date-fns';
import fr from 'date-fns/locale/fr';
import {
  trim,
  range,
  flatten,
  padStart,
  get,
  omit,
  pickBy,
  snakeCase,
  times,
  capitalize,
} from 'lodash';
import queryString from 'query-string';
import decamelizeKeysDeep from 'decamelize-keys-deep';

export const formatDate = (date, frmt) => {
  if (!Boolean(date)) {
    return '';
  }

  if (!Boolean(frmt)) {
    try {
      return new Date(date).toISOString();
    } catch (err) {
      return '';
    }
  }

  try {
    return format(new Date(date), frmt, { locale: fr });
  } catch (err) {
    try {
      return format(date, frmt, { locale: fr });
    } catch (err) {
      return '';
    }
  }
};

export const ERRORS = {
  REQUIRED: 'Ce champ est requis',
  TAKEN: 'Cette valeur est déjà prise',
  INVALID: 'Cette valeur n’est pas valide',
  EMAIL: 'Cette adresse mail n’est pas valide',
  EMAIL_NOT_FOUND:
    'L’adresse mail entrée n’existe pas dans notre base de données',
  MIN_SIX: '6 caractères minimum',
  NOT_FOUND: 'Le lien du mail d’invitation a expiré. Merci de nous contacter.',
  PASSWORDS_DO_NOT_MATCH: 'Les mots de passe ne concordent pas',
  ADDRESS_NO_FOUND: 'Cette adresse n’est pas valide',
  AMOUNT: 'Valeur invalide',
  COMMON: 'Une erreur est survenue, veuillez rééssayer ultérieurement.',
  VALIDITY_END_DATE:
    'Une erreur est survenue, la date de fin de validé est postérieur à la date de fin de la commande',
  MODEL_WEEKS_DAY_GREATER_THAN_VISIT_COUNT:
    'Le nombre de passage par semaine ne peut pas être supérieur au nombre de visites programmées.',
  NUMBER: 'Ce champ doit être un nombre',
};

export const pluralize = (input, count) =>
  input
    .split(' ')
    .map(i => `${i}${count > 1 ? 's' : ''}`)
    .join(' ');

export const concatenate = (...chunks) =>
  trim(chunks.filter(Boolean).reduce((acc, curr) => `${acc} ${curr} `, ''));

export const hours = flatten(
  range(0, 24)
    .map(hour => padStart(hour, 2, '0'))
    .map(hour => [`${hour}h00`, `${hour}h30`]),
);

export const getSecondsFromHours = hour => {
  const r = hour.split('h');

  return r[0] * 3600 + r[1] * 60;
};

export const RAMP_TYPE = {
  mobile: 'Mobile',
  fixed: 'Fixe',
  hatch: 'Hayon',
};

export const orderedDays = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const DAYS = {
  monday: 'lundi',
  tuesday: 'mardi',
  wednesday: 'mercredi',
  thursday: 'jeudi',
  friday: 'vendredi',
  saturday: 'samedi',
  sunday: 'dimanche',
};

export const formatTime = seconds => {
  const time = addSeconds(startOfDay(Date.now()), seconds);
  return formatDate(time, 'HH:mm');
};

export const getParam = (location, param) =>
  get(queryString.parse(location.search), param);

export const getNameFromState = state => {
  switch (state) {
    case 'new':
      return 'Nouveau';
    case 'updated':
      return 'Modifié';
    case 'all_work_orders_generated':
      return 'ODMs complètement générés';
    case 'partial_work_orders_generated':
      return 'ODMs partiellement générés';
    case 'duplicated':
      return 'Dupliqué';
    case 'imported':
      return 'Importé';
    default:
      return '';
  }
};

export const getOdmNameFromState = state => {
  switch (state) {
    case 'new':
      return 'À renseigner';
    case 'ongoing_control':
      return 'À contrôler';
    case 'controlled':
      return 'Contrôlé';
    case 'billed':
      return 'Facturé';
    case 'archived':
      return 'Archivé';

    default:
      return '';
  }
};

export const getColorFromState = state => {
  switch (state) {
    case 'new':
      return 'tealTag';
    case 'quoted':
      return 'blueTag';
    case 'updated':
      return 'yellowTag';
    case 'validated_quote':
      return 'greenTag';
    case 'all_work_orders_generated':
      return 'purpleTag';
    case 'partial_work_orders_generated':
      return 'pinkTag';
    case 'imported':
      return 'pinkTag';
    default:
      return 'greyTag';
  }
};

export const getNameFromQuotationState = state => {
  switch (state) {
    case 'obsolete':
      return 'Obsolète';
    case 'confirmed':
      return 'Validé';
    case 'generated':
      return 'Généré';
    default:
      return 'Inexistant';
  }
};

export const getLabelColorFromState = state => {
  switch (state) {
    case 'new':
      return 'tealLabel';
    case 'quoted':
      return 'blueLabel';
    case 'updated':
      return 'yellowLabel';
    case 'validated_quote':
      return 'greenLabel';
    default:
      return 'greyLabel';
  }
};

export const formatIsOpenOnSemiDay = ({ day, timeSlot }) => {
  try {
    const { openHours, closeHours } = day;
    const startOfToday = startOfDay(new Date());
    const endOfToday = endOfDay(new Date());
    const noonToday = addHours(startOfToday, 12);

    const startOfInterval = addSeconds(startOfToday, openHours);
    const endOfInterval = addSeconds(startOfToday, closeHours);

    if (timeSlot === 'am') {
      return areIntervalsOverlapping(
        {
          start: startOfToday,
          end: noonToday,
        },
        {
          start: startOfInterval,
          end: endOfInterval,
        },
      );
    }

    return areIntervalsOverlapping(
      { start: noonToday, end: endOfToday },
      { start: startOfInterval, end: endOfInterval },
    );
  } catch (err) {
    return {
      isOpenInMorning: false,
      isOpenInAfternoon: false,
    };
  }
};

export const getInterval = ({ interval, compact }) => {
  switch (interval) {
    case 'month':
      return 'mois';
    case 'week':
      return compact ? 'sem' : 'semaine';
    default:
      return interval;
  }
};

export const formatProduct = line =>
  cleanPayload({
    serviceId: Number(line.serviceId) || line?.product?.service?.id,
    resourceId: Number(line.resourceId) || line?.product?.resource?.id,
    billingMethod: line.billingMethod || line?.product?.billingMethod,
    unit: line.productUnit || line?.product?.unit,
    frequency: line.frequency || line?.product?.frequency,
  });

export const omitPayload = payload =>
  omit(payload, ['id', 'createdAt', 'updatedAt']);

export const cleanPayload = payload => pickBy(omitPayload(payload));

export const formatAmount = amount => {
  if (typeof Intl === 'undefined') {
    return `${(amount / 100).toFixed(2)}\u00a0€`;
  }
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  }).format(amount / 100);
};

export const overrideSearchWithParams = ({ location, ...rest }) => {
  const output = queryString.stringify(
    pickBy({
      ...queryString.parse(location.search),
      ...rest,
    }),
  );

  if (!Boolean(output)) {
    return null;
  }

  return `?${output}`;
};

export const billingMethodOptions = [
  { label: 'Facture par cumul sans ventilation par site', value: 'by_product' },
  {
    label: 'Facture détaillée',
    value: 'by_work_order_task',
  },
  {
    label: 'Facture par cumul avec ventilation par site',
    value: 'by_collection_point',
  },
];

export const billingCycleOptions = [
  { label: "À l'opération", value: 'adhoc' },
  { label: 'Mensuelle', value: 'monthly' },
  { label: 'Trimestrielle', value: 'quarterly' },
  { label: 'Semestrielle', value: 'biannual' },
  { label: 'Annuelle', value: 'yearly' },
];

export const vehicleTypeOptions = [
  { label: 'VL', value: 'car' },
  { label: 'PL', value: 'truck' },
  { label: 'Cycle', value: 'bike' },
  { label: 'Remorque', value: 'trailer' },
];

export const getVehicleTypeFromKind = kind => {
  switch (kind) {
    case 'car':
      return 'VL';
    case 'truck':
      return 'PL';
    case 'bike':
      return 'Cycle';
    case 'trailer':
      return 'Remorque';
    default:
      return '';
  }
};

export const loadingTypeOptions = [
  { label: 'Hayon', value: 'hatchback' },
  { label: 'Fixe', value: 'fixed' },
  { label: 'Mobile', value: 'mobile' },
  { label: 'Sans', value: 'none' },
];

export const getLoadingTypeFromLoadingType = loading_type => {
  switch (loading_type) {
    case 'hatchback':
      return 'Hayon';
    case 'fixed':
      return 'Fixe';
    case 'mobile':
      return 'Mobile';
    case 'none':
      return 'Sans';
    default:
      return '';
  }
};

export const loadingVolumeOptions = [
  { label: '0,5', value: '0.5' },
  { label: '1', value: '1.0' },
  { label: '3', value: '3.0' },
  { label: '5', value: '5.0' },
  { label: '7', value: '7.0' },
  { label: '9', value: '9.0' },
  { label: '11', value: '11.0' },
  { label: '12', value: '12.0' },
  { label: '13', value: '13.0' },
  { label: '14', value: '14.0' },
  { label: '15', value: '15.0' },
  { label: '16', value: '16.0' },
  { label: '17', value: '17.0' },
  { label: '18', value: '18.0' },
  { label: '19', value: '19.0' },
  { label: '20', value: '20.0' },
  { label: '21', value: '21.0' },
  { label: '22', value: '22.0' },
  { label: '23', value: '23.0' },
  { label: '24', value: '24.0' },
  { label: '25', value: '25.0' },
  { label: '26', value: '26.0' },
  { label: '27', value: '27.0' },
  { label: '28', value: '28.0' },
  { label: '29', value: '29.0' },
  { label: '30', value: '30.0' },
];

export const getLoadingVolumeFromVolume = volume => {
  switch (volume) {
    case '0.5':
      return '0.5';
    default:
      const result = parseInt(volume);
      return isNaN(result) ? '' : parseInt(volume);
  }
};

export const critairOptions = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '0', value: '0' },
];

export const fuelOptions = [
  { label: 'Essence', value: 'petrol' },
  { label: 'Diesel', value: 'diesel' },
  { label: 'GNV', value: 'ngv' },
  { label: 'Electrique', value: 'electric' },
  { label: 'Musculaire', value: 'human_strength' },
];

export const getFuelFromFuelType = fuel_type => {
  switch (fuel_type) {
    case 'petrol':
      return 'Essence';
    case 'diesel':
      return 'Diesel';
    case 'ngv':
      return 'GNV';
    case 'electric':
      return 'Electrique';
    case 'human_strength':
      return 'Musculaire';
    default:
      return '';
  }
};

export const payloadOptions = [
  { label: '700', value: '700' },
  { label: '800', value: '800' },
  { label: '900', value: '900' },
  { label: '1000', value: '1000' },
  { label: '1100', value: '1100' },
  { label: '1200', value: '1200' },
  { label: '1425', value: '1425' },
  { label: '5000', value: '5000' },
  { label: '10000', value: '10000' },
  { label: '15000', value: '15000' },
  { label: '20000', value: '20000' },
  { label: '25000', value: '25000' },
];

export const userFunctionsOptions = [
  {
    label: 'Administrateur',
    value: 'administrator',
  },
  { label: 'Comptabilité', value: 'accounting' },
  {
    label: 'Responsable de pôle',
    value: 'manager',
  },
  { label: 'Planification', value: 'planning' },
  { label: 'Commercial', value: 'sales' },
  { label: 'Pesée', value: 'weighing' },
];

export const getLabelFromOptionsList = value => {
  const functionOption = userFunctionsOptions.find(
    functionInCompany => functionInCompany.value === value,
  );
  return functionOption?.label;
};

// TODO: remove this function and useSearchParams hook instead
export const setSearchParams = ({ history, location, params }) => {
  const query = queryString.parse(location.search);
  const newSearch = queryString.stringify(pickBy({ ...query, ...params }));
  history.push(`${location?.pathname}${newSearch ? '?' + newSearch : ''}`);
};

const buildFormData = (formData, data, parentKey) => {
  if (
    Boolean(data) &&
    typeof data === 'object' &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach(key => {
      if (Array.isArray(data[key])) {
        const decamelizedArray = decamelizeKeysDeep(data[key], '_');
        const decamelizedKey = decamelizeKeysDeep(key, '_');
        formData.append(decamelizedKey, JSON.stringify(decamelizedArray));
      } else {
        buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key,
        );
      }
    });
  } else {
    const value = data ?? '';
    formData.append(parentKey, value);
  }
};

export const jsonToFormData = (data, filePath) => {
  const avatarData = data?.[filePath];
  const formData = new FormData();
  if (avatarData) {
    buildFormData(formData, {
      ...decamelizeKeysDeep(data, '_'),
      avatar: data?.[filePath],
    });
  } else {
    buildFormData(formData, {
      ...decamelizeKeysDeep(data, '_'),
    });
  }

  return formData;
};

export const formatNumberToPrettyString = (number, fixedNbDecimals = 2) => {
  if (isNaN(number)) {
    return '0';
  }
  if (Number.isInteger(number)) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  } else {
    const parts = Number(number).toFixed(fixedNbDecimals).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
  }
};

export const formatSort = (sort = '') =>
  sort.charAt(0) === '-'
    ? sort.split('-').map(snakeCase).join(',-').replace(',-', '-')
    : sort.split(',').map(snakeCase).join(',');

export const replaceCommaByPoint = number => {
  if (!Boolean(number)) {
    return '';
  }
  return `${number}`.replace(/,/g, '.');
};

export const getRole = role => {
  switch (role) {
    case 'guest':
      return 'Invité';
    case 'admin':
      return 'Admin';
    case 'member':
      return 'Membre';
    default:
      return '';
  }
};

export const isClosed = day => {
  const allHoursAreNull = [
    day?.openHours,
    day?.middleOpenHours,
    day?.middleCloseHours,
    day?.closeHours,
  ].every(hour => hour === null);

  return (day?.closedMorning && day?.closedAfternoon) || allHoursAreNull;
};

export const getFrequencyLabel = frequency => {
  switch (frequency) {
    case '1':
      return 'Régulière';
    case '2':
      return 'Ponctuelle';
    default:
      return '';
  }
};

export const formatPrice = price => Number(String(price).replace(/,/g, '.'));

export const formatCurrency = price => {
  if (price === undefined || price === null || isNaN(price)) {
    return '-';
  } else if (Number(price) % 1 === 0) {
    return Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  }
  return Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  }).format(price);
};

export const WORK_ORDER_SEARCH_OPTIONS = [
  { label: 'Point de collecte', value: 'filter[collectionPoint]' },
  { label: 'ID ODM', value: 'filter[workOrderId]' },
  { label: 'ID de commande', value: 'filter[orderId]' },
  { label: 'Client commande', value: 'filter[axonautCompanyName]' },
  { label: 'Tournée', value: 'filter[truckIdentifier]' },
];

export const WEIGHING_WORK_ORDERS_SEARCH_OPTIONS = [
  {
    label: 'ID ODM',
    value: 'filter[id]',
  },
  WORK_ORDER_SEARCH_OPTIONS[2],
  WORK_ORDER_SEARCH_OPTIONS[0],
  WORK_ORDER_SEARCH_OPTIONS[4],
];

export const createMonthlyDatesOrdered = (totalNbOfMonths, startAt, endAt) => {
  const months = [];
  const startDate = startOfMonth(new Date(startAt));

  times(totalNbOfMonths, nbMonths => {
    const date = add(startDate, { months: nbMonths });

    if (isBefore(date, endAt))
      months.push({
        label: capitalize(formatDate(date, 'MMMM yyyy')),
        value: format(date, "yyyy-MM-dd'T'HH:mm:ss.sssXXX"),
      });
  });

  return months;
};

export const isManagerOfFranchise = (userEmail, managerEmail) =>
  userEmail === managerEmail;
