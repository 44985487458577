import * as React from 'react';

const IcChevronUpDown = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
    style={{ ...props.style, color: props.color, fontSize: props.size }}
  >
    <g fill="#000" fillRule="evenodd" clipRule="evenodd">
      <path d="M16 9.75a.75.75 0 0 0 .586-1.219l-4-5a.75.75 0 0 0-1.172 0l-4 5A.75.75 0 0 0 8 9.75h8ZM8 15.25a.75.75 0 0 0-.586 1.218l4 5a.75.75 0 0 0 1.172 0l4-5A.75.75 0 0 0 16 15.25H8Z" />
    </g>
  </svg>
);

export default IcChevronUpDown;
