import React from 'react';
import styled from 'styled-components';
import Spinner from '@tymate/elise/components/Spinner';

const Wrapper = styled.div`
  display: flex;
  min-height: 240px;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.separator};
`;

const Loading = ({ size, ...props }) => (
  <Wrapper {...props}>
    <Spinner size={size ? size : 80} borderSize="0.4em" />
  </Wrapper>
);

export default Loading;
