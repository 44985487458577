import React from 'react';
import { Button as MgtButton } from '@tymate/margaret';
import { Link } from 'react-router-dom';

const Button = props => (
  <MgtButton
    {...props}
    as={
      Boolean(props.as) && !Boolean(props.disabled)
        ? props.as
        : Boolean(props.to)
        ? Link
        : null
    }
  />
);

export default Button;
