import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdKeyboardArrowDown,
} from 'react-icons/md';
import { PopoverItem, PopoverItemButton, PopoverMenu } from '../ui/popover';
import { useDeepCompareEffect } from 'react-use';
import { Dropdown, Tooltip } from '@tymate/elise/components';
import Truncate from 'react-truncate';
import { Stack } from '@tymate/margaret';

const Label = styled.label`
  display: block;
  color: inherit;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  font-weight: inherit;

  ${({ isField }) =>
    isField &&
    css`
      margin-bottom: ${({ theme }) => theme.spacing(0.25)};
      font-size: 14px;
      color: ${({ theme }) => theme.textLighten};
    `}
`;

const Trigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.separator};
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  font-size: 1rem;
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  width: 100%;

  ${({ size }) =>
    css`
      width: ${size}px;
    `}

  ${({ disabled, theme }) =>
    Boolean(disabled) &&
    css`
      background-color: ${theme.disabled};
      color: #6d6d6d;
    `}
`;

const Item = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(0.5)};
`;

const MultiSelect = ({
  value = [],
  label,
  onChange,
  options,
  disabled,
  variant,
  wrapperStyle,
  size,
  hasError,
  hasCompactDropList = false,
  children,
  isField,
  allOptionsLabel = 'Tous',
}) => {
  const dropdownRef = useRef();
  const [selectedValues, setSelectedValues] = useState(value);
  const [isTruncated, setIsTruncated] = useState(false);
  const allChecked = selectedValues.length === options.length;

  useDeepCompareEffect(() => {
    if (onChange) {
      onChange(selectedValues);
    }
  }, [{ selectedValues }]);

  return (
    <>
      <Stack alignY="center" alignX="space-between">
        {Boolean(label) && <Label isField={isField}>{label}</Label>}
      </Stack>

      <Dropdown
        disabled={disabled}
        ref={dropdownRef}
        wrapperStyle={wrapperStyle}
        shouldCloseDropdownOnTriggerClick
        trigger={
          <Trigger
            disabled={disabled}
            variant={variant}
            hasError={hasError}
            size={size}
          >
            <Tooltip
              disabled={!isTruncated}
              tip={selectedValues.map(({ label }) => label).join(', ')}
            >
              <div style={{ width: '100%' }}>
                <Truncate onTruncate={value => setIsTruncated(value)}>
                  {selectedValues?.length > 0
                    ? selectedValues.map(({ label }) => label).join(', ')
                    : allOptionsLabel}
                </Truncate>
              </div>
            </Tooltip>
            <MdKeyboardArrowDown size={24} />
          </Trigger>
        }
      >
        <PopoverMenu size={size} isACompactDropList={hasCompactDropList}>
          <PopoverItem>
            <PopoverItemButton
              type="button"
              onClick={() => {
                if (allChecked) {
                  setSelectedValues([]);
                } else {
                  setSelectedValues([...options]);
                }
              }}
              isActive={allChecked}
              hasBottomBorder
            >
              <Item>
                <div>
                  {allChecked ? (
                    <MdCheckBox size={22} />
                  ) : (
                    <MdCheckBoxOutlineBlank size={22} />
                  )}
                </div>
                <div>{allOptionsLabel}</div>
              </Item>
            </PopoverItemButton>
          </PopoverItem>
          {options.map((option, index) => {
            const isChecked = selectedValues.some(
              ({ value }) => option?.value === value,
            );
            return (
              <PopoverItem key={index}>
                <PopoverItemButton
                  type="button"
                  onClick={() => {
                    if (isChecked) {
                      setSelectedValues(
                        selectedValues.filter(
                          ({ value }) => value !== option?.value,
                        ),
                      );
                    } else {
                      setSelectedValues([...selectedValues, option]);
                    }
                  }}
                  isActive={isChecked}
                >
                  <Item>
                    <div>
                      {isChecked ? (
                        <MdCheckBox size={22} />
                      ) : (
                        <MdCheckBoxOutlineBlank size={22} />
                      )}
                    </div>
                    <div>{option?.label}</div>
                  </Item>
                </PopoverItemButton>
              </PopoverItem>
            );
          })}
          {children}
        </PopoverMenu>
      </Dropdown>
    </>
  );
};

export default MultiSelect;
