import React from 'react';
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdIndeterminateCheckBox,
} from 'react-icons/md';
import { ActionButton } from '@tymate/elise/ui';
import { Stack } from '@tymate/margaret';

const Checkbox = ({
  checked,
  onClick,
  indeterminate,
  disabled,
  label,
  color = 'primary',
  type = 'submit',
}) => (
  <Stack direction="row" gap={1}>
    <ActionButton
      onClick={onClick}
      isActive={checked}
      disabled={disabled}
      type={type}
    >
      {checked ? (
        <MdCheckBox color={color} disabled={disabled} />
      ) : indeterminate ? (
        <MdIndeterminateCheckBox disabled={disabled} />
      ) : (
        <MdCheckBoxOutlineBlank disabled={disabled} />
      )}
    </ActionButton>
    <div>{label}</div>
  </Stack>
);

export default Checkbox;
