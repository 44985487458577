import { ERRORS } from '@tymate/elise/utils';
import * as Yup from 'yup';

export const validationFloat = () =>
  Yup.string()
    .transform(value => {
      const formattedNumber = Number(String(value)?.replace(/,/g, '.'));

      return isNaN(formattedNumber) ? value : String(formattedNumber);
    })
    .typeError(ERRORS.AMOUNT)
    .nullable(true);
