import React from 'react';
import styled from 'styled-components';
import { Formik } from 'formik';
import { Form, TextField } from '../components/Forms';
import { Button, Buttons } from '../ui';
import { ERRORS } from '@tymate/elise/utils';
import * as Yup from 'yup';

const Title = styled.h2`
  color: ${({ theme }) => theme.text};
  font-size: 1.5rem;
  line-height: 2;
  text-align: center;
  margin: 0;
`;

const Explanation = styled.p`
  color: ${({ theme }) => theme.textLight};
  line-height: 1.5;
  text-align: center;
`;

const passwordConfirmationSchema = Yup.object().shape({
  password: Yup.string().min(6, ERRORS.MIN_SIX),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password')], ERRORS.PASSWORDS_DO_NOT_MATCH)
    .when('password', {
      is: true,
      then: Yup.string().required(ERRORS.REQUIRED),
    }),
});

const UpdatePasswordForm = ({ onSubmit }) => (
  <Formik
    onSubmit={onSubmit}
    validationSchema={passwordConfirmationSchema}
    initialValues={{ password: '' }}
  >
    {({ isSubmitting }) => (
      <Form autoComplete="off">
        <Title>Mot de passe oublié ?</Title>
        <Explanation>Entrez votre nouveau mot de passe</Explanation>
        <TextField name="password" label="Mot de passe" type="password" />
        <TextField
          name="passwordConfirmation"
          label="Confirmation du mot de passe"
          type="password"
        />
        <Buttons hasMarginTop>
          <Button variant="primary" size="full" isLoading={isSubmitting}>
            Valider
          </Button>
        </Buttons>
      </Form>
    )}
  </Formik>
);

export default UpdatePasswordForm;
