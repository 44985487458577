import React, { useState } from 'react';
import Tooltip from '@tymate/elise/components/Tooltip';
import Truncate from 'react-truncate';
import styled, { css } from 'styled-components';

const TagWrapper = styled.div`
  display: inline;
  color: #fff;
  padding: ${({ theme }) => theme.spacing(0.125)}
    ${({ theme }) => theme.spacing(0.5)};
  border-radius: 4px;
  font-size: 0.875rem;
  white-space: break-spaces;
  overflow: hidden;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.greenTag};

  ${({ background }) =>
    background &&
    css`
      background-color: ${({ theme }) => theme[background]};
    `};

  ${({ color }) =>
    color &&
    css`
      color: ${({ theme }) => theme[color]};
    `};
`;

const Tag = ({
  value,
  options,
  defaultOption,
  styleWrapper,
  children,
  truncateWidth = 100,
  noTruncate = false,
}) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const option = defaultOption
    ? defaultOption
    : options.find(({ value: optionValue }) => optionValue === value);

  if (!option) {
    return null;
  }

  return noTruncate ? (
    <TagWrapper
      background={`${option?.color}Tag`}
      color={`${option?.color}Label`}
      style={styleWrapper}
    >
      {option?.label}
      {children}
    </TagWrapper>
  ) : (
    <Tooltip disabled={!isTruncated} variant="medium" tip={option?.label}>
      <TagWrapper
        background={`${option?.color}Tag`}
        color={`${option?.color}Label`}
        style={styleWrapper}
      >
        <Truncate
          onTruncate={value => setIsTruncated(value)}
          lines={1}
          trimWhitespace
          ellipsis={<span>...</span>}
          width={truncateWidth}
        >
          {option?.label}
        </Truncate>
        {children}
      </TagWrapper>
    </Tooltip>
  );
};

export default Tag;
