import React, { useState } from 'react';
import Tooltip from '@tymate/elise/components/Tooltip';
import LinesEllipsis from 'react-lines-ellipsis';

const OneLineTruncate = ({ text }) => {
  const [isTruncated, setIsTruncated] = useState(false);

  const handleReflow = ({ clamped }) => {
    if (clamped && !isTruncated) {
      setIsTruncated(true);
    }
  };

  return (
    <Tooltip disabled={!isTruncated} variant="medium" tip={text}>
      <LinesEllipsis
        text={text || ''}
        trimRight
        basedOn="letters"
        onReflow={handleReflow}
      />
    </Tooltip>
  );
};

export default OneLineTruncate;
