import queryString from 'query-string';
import { omit, pickBy } from 'lodash';

export const getPageAndRestFromQuery = query => {
  const parsedQuery = queryString.parse(query);
  const rest = omit(parsedQuery, 'page[number]');

  return {
    page: parsedQuery['page[number]'] || 1,
    query: `?${queryString.stringify(rest)}`,
  };
};

export const overridePage = (search, page) =>
  queryString.stringify(
    pickBy({
      ...queryString.parse(search),
      page: page === 1 ? null : page,
    }),
  );
