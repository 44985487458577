import React, { useState } from 'react';
import { createGlobalStyle } from 'styled-components';
import { AppContext } from '../contexts';
import { ToastContainer, toast, Slide } from 'react-toastify';
import { MdClose } from 'react-icons/md';
import 'react-toastify/dist/ReactToastify.css';
import { useDeepCompareEffect, useLocation } from 'react-use';
import { useMargaret } from '@tymate/margaret';

const ToastsStyles = createGlobalStyle`
  .Toastify {
    .Toastify__toast-container {
      top: calc(80px + ${({ theme }) => theme.spacing()});
    }

    .Toastify__toast {
      border-radius: 4px;
      padding: ${({ theme }) => theme.spacing()};
      align-items: center;

      &:before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        width: 4px;
      }

      svg {
        font-size: 20px;
      }
    }

    .Toastify__toast--default {
      background-color: #e1eefb;
      color: rgba(0,0,0,0.87);

      &:before {
        background-color: #62acec;
      }

      svg {
        color: #62acec;
      }
    }

    .Toastify__toast--success {
      background-color: #d4edda;
      color: rgba(0,0,0,0.87);

      &:before {
        background-color: #82c886;
      }

      svg {
        color: #82c886;
      }
    }

    .Toastify__toast--error {
      background-color: #fee7e1;
      color: rgba(0,0,0,0.87);

      &:before {
        background-color: #ff8863;
      }

      svg {
        color: #ff8863;
      }
    }
  }
`;

const AppProvider = ({ children }) => {
  const location = useLocation();
  const key = location?.state?.key;
  const { collapseMainNav } = useMargaret();

  const notify = (message, options) =>
    toast(message, { transition: Slide, type: 'success', ...options });

  const [routesNames, setRoutesNames] = useState({});

  const setRouteName = (name, path) => {
    setRoutesNames({
      ...routesNames,
      [path]: name,
    });
  };

  const setCurrentRouteName = name => {
    setRoutesNames({
      ...routesNames,
      [location.pathname]: name,
    });
  };

  useDeepCompareEffect(() => {
    collapseMainNav();
  }, [{ key }]);

  return (
    <AppContext.Provider
      value={{ notify, setRouteName, routesNames, setCurrentRouteName }}
    >
      {children}

      <ToastsStyles />
      <ToastContainer closeButton={<MdClose />} />
    </AppContext.Provider>
  );
};

export default AppProvider;
