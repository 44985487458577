import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import {
  Field,
  Form as RawForm,
  ErrorMessage as RawErrorMessage,
  useField,
} from 'formik';
import {
  FormField,
  FormLabel,
  Input,
  ToggleFormLabel,
  Textarea,
  TextFieldStepper,
  SubLabel,
} from '../ui/forms';
import { ButtonReset, Button } from '../ui';
import { uniqueId, get } from 'lodash';
import {
  MdCheckBox,
  MdCheckBoxOutlineBlank,
  MdVisibility,
  MdVisibilityOff,
  MdAdd,
  MdRemove,
  MdClear,
} from 'react-icons/md';
import {
  SegmentedControl,
  Select,
  SearchableSelect,
  SearchableSelectManualSettings,
} from '@tymate/elise/components';
import { useDeepCompareEffect } from 'react-use';
import { getLabelFromOptionsList } from '../utils';
import { Stack } from '@tymate/margaret';

export const TagsListWrapper = styled(Stack).attrs()`
  ${({ tagsListAlignment }) =>
    tagsListAlignment === 'flex-end' &&
    css`
      justify-content: flex-end;
      margin-right: ${({ theme }) => theme.spacing(3)};
    `}
`;

export const TagsList = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

export const TagItem = styled.span`
  background-color: ${({ theme }) => theme.primaryLight};
  color: white;
  padding: ${({ theme }) => theme.spacing(0.5)};
  margin-left: ${({ theme }) => theme.spacing(0.25)};
  margin-right: ${({ theme }) => theme.spacing(0.25)};
  border-radius: 20px;
  font-size: 14px;
`;

const switchDimension = 30;
const switchBorder = 0;
const triggerSize = switchDimension - 2 * switchBorder;

export const ErrorMessageWrapper = styled.div`
  padding-top: ${({ theme }) => theme.spacing(0.125)};
  color: ${({ theme }) => theme.error};
  font-size: 0.825rem;
  position: absolute;
  top: 100%;

  ${({ noAbsolute }) =>
    noAbsolute &&
    `
    position: relative;
  `}
`;

export const ErrorMessage = props => (
  <RawErrorMessage {...props} component={ErrorMessageWrapper} />
);

const InputContainer = styled.div`
  position: relative;
  display: flex;
`;

const PasswordButton = styled(ButtonReset)`
  position: absolute;
  width: 44px;
  height: 44px;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RenderTextNumberField = props => {
  const [type] = useState(props.type || 'text');
  const { placeholder, field, inputValue, label, form, variant, ...rest } =
    props;
  const hasError = Boolean(form.errors[field.name] && form.touched[field.name]);

  return (
    <FormField>
      {label ? <FormLabel>{label}</FormLabel> : null}
      <InputContainer>
        <Input
          value={inputValue}
          variant={variant}
          {...field}
          {...rest}
          type={type}
          placeholder={placeholder}
          hasError={hasError}
        />
        <Button variant={variant}>valider</Button>
      </InputContainer>
      <ErrorMessage {...field} />
    </FormField>
  );
};

export const TextNumberField = props => (
  <Field {...props} component={RenderTextNumberField} />
);

export const TextField = ({
  placeholder,
  label,
  showOptionalLabel = false,
  onChange,
  ...props
}) => {
  const [field, meta, { setValue }] = useField(props.name);
  const [isPassword] = useState(props.type === 'password');
  const [type, setType] = useState(props.type || 'text');

  const hasError = Boolean(meta.touched) && Boolean(meta.error);

  return (
    <FormField>
      <Stack alignY="center" alignX="space-between">
        {label && <FormLabel>{label}</FormLabel>}
        {showOptionalLabel && <SubLabel>{'(optionnel)'}</SubLabel>}
      </Stack>
      <InputContainer style={type === 'number' ? { maxWidth: 140 } : null}>
        <Input
          {...field}
          onChange={e => {
            field.onChange(e);
            if (Boolean(onChange)) {
              onChange(e.target.value);
            }
          }}
          type={type ? type : 'text'}
          placeholder={placeholder}
          hasError={hasError}
          min={0}
          step="any"
          disabled={props.disabled}
          autocompleteOn={props.autocompleteOn}
        />

        {isPassword && (
          <PasswordButton
            disabled={props.disabled}
            type="button"
            onClick={() => setType(type === 'password' ? 'text' : 'password')}
            tabIndex={-1}
          >
            {type === 'text' ? <MdVisibility /> : <MdVisibilityOff />}
          </PasswordButton>
        )}

        {(type === 'number' || type === 'fullNumber') && (
          <>
            <TextFieldStepper
              disabled={props.disabled}
              type="button"
              onClick={() =>
                setValue(
                  Boolean(props.max)
                    ? Math.min(props.max, (field.value || 0) + 1)
                    : (field.value || 0) + 1,
                )
              }
            >
              <MdAdd />
            </TextFieldStepper>

            <TextFieldStepper
              disabled={props.disabled}
              variant="bottom"
              type="button"
              onClick={() => setValue(Math.max((field.value || 0) - 1, 0))}
            >
              <MdRemove />
            </TextFieldStepper>
          </>
        )}

        <ErrorMessage {...field} />
      </InputContainer>
    </FormField>
  );
};

const RenderTextareaField = ({ field, label, form, ...props }) => {
  const hasError = Boolean(form.errors[field.name]) && form.touched[field.name];

  return (
    <FormField>
      {label && <FormLabel hasError={hasError}>{label}</FormLabel>}
      <Textarea {...field} {...props} hasError={hasError} />
      <ErrorMessage name={field.name} />
    </FormField>
  );
};

export const TextareaField = props => {
  const [id] = useState(uniqueId());

  return <Field {...props} id={id} component={RenderTextareaField} />;
};

export const Form = styled(RawForm)``;

const ToggleSwitchWrapper = styled.div`
  display: block;
  width: ${props =>
    props.bare ? 1.75 * switchDimension : 2.5 * switchDimension}px;
  height: ${switchDimension}px;
  position: relative;
  box-shadow: inset 0 0 0 2px #f2f2f2;
  border-color: ${({ checked, theme }) =>
    checked ? theme.text : theme.textLight};
  background: ${({ theme }) => theme.disabled};
  border-radius: ${switchDimension}px;
  transition: border-color 150ms ease-out, background-color 150ms ease-out;

  [type='checkbox'] {
    display: none;
  }

  ${props =>
    props.checked &&
    css`
      background-color: ${({ theme }) => theme.primary};
      box-shadow: none;
    `};
`;

const ToggleSwitchTrigger = styled.a`
  position: absolute;
  background-color: #fff;
  top: 0;
  height: ${triggerSize}px;
  width: ${triggerSize}px;
  transition: left 150ms ease-out, background-color 150ms ease-out;
  border-radius: 100%;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
  border: 0;
  left: ${props => (props.checked ? 'calc(100% - ' + triggerSize + 'px)' : 0)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;

  &:before {
    position: absolute;
    top: -6px;
    right: -3 * ${switchDimension}px;
    bottom: -6px;
    left: -3 * ${switchDimension}px;
    content: '';
    display: block;
  }
`;

const ToggleSwitchTrack = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  color: #fff;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  user-select: none;
`;

const SwitchLabel = styled.span`
  color: ${({ theme }) => theme.text};

  ${({ isLeft }) =>
    isLeft &&
    css`
      margin-right: ${({ theme }) => theme.spacing()};
    `}

  ${({ isRight }) =>
    isRight &&
    css`
      margin-left: ${({ theme }) => theme.spacing()};
    `}
`;

export const ToggleSwitch = ({
  onChange,
  checked,
  disabled,
  label,
  inverted,
  id,
}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {!inverted && <SwitchLabel isLeft>{label}</SwitchLabel>}
      <ToggleSwitchWrapper bare checked={checked} disabled={disabled}>
        <ToggleSwitchTrack
          htmlFor={id}
          checked={checked}
          disabled={disabled}
          onClick={onChange}
        />
        <ToggleSwitchTrigger
          id={id}
          checked={checked}
          disabled={disabled}
          onClick={onChange}
        />
      </ToggleSwitchWrapper>
      {inverted && <SwitchLabel isRight>{label}</SwitchLabel>}
    </div>
  );
};

const RenderToggleSwitch = props => {
  const { field, label, form, inverted, disabled } = props;

  return (
    <FormField>
      <ToggleFormLabel>
        <ToggleSwitch
          label={label}
          checked={field.value}
          inverted={inverted}
          disabled={disabled}
          onChange={() => form.setFieldValue(field.name, !field.value)}
          id={field.name}
        />
      </ToggleFormLabel>
      <ErrorMessage {...field} />
    </FormField>
  );
};

export const ToggleSwitchField = props => (
  <Field {...props} component={RenderToggleSwitch} />
);

const RenderSimpleToggleSwitch = props => {
  const { field, label, form, inverted } = props;
  const hasError = Boolean(form.errors[field.name] && form.touched[field.name]);

  return (
    <label>
      <input
        type="checkbox"
        {...field}
        {...props}
        style={{ display: 'none' }}
      />

      <ToggleSwitch
        {...field}
        label={label}
        checked={field.value}
        hasError={hasError}
        inverted={inverted}
        id={field.name}
      />
    </label>
  );
};

export const SimpleToggleSwitchField = props => (
  <Field {...props} component={RenderSimpleToggleSwitch} />
);

export const SelectField = ({ onChange = () => {}, ...props }) => {
  return (
    <Field name={props.name}>
      {({
        field,
        form: { touched, errors, setFieldValue, setFieldTouched },
      }) => {
        const hasError = Boolean(
          get(errors, field.name) && get(touched, field.name),
        );

        return (
          <FormField>
            <Select
              {...props}
              hasError={hasError}
              onChange={({ value }) => {
                setFieldTouched(field.name, true);
                setFieldValue(field.name, value);
                onChange(value);
              }}
              value={field.value}
              variant="input"
              size="auto"
              showOptionalLabel={props.showOptionalLabel}
              isField
            />
            {!props.shouldHideErrorMessage && <ErrorMessage {...field} />}
          </FormField>
        );
      }}
    </Field>
  );
};

export const SearchableSelectField = props => {
  const [valueLabel, setValueLabel] = useState(props.initialValueLabel);

  return (
    <Field name={props.name}>
      {({ field, form: { touched, errors, setFieldValue } }) => {
        const hasError = Boolean(errors[field.name] && touched[field.name]);
        return (
          <FormField>
            <SearchableSelect
              {...props}
              selectedItem={field.value}
              renderTrigger={() => (Boolean(field.value) ? valueLabel : '')}
              hasError={hasError}
              onChange={value => {
                setFieldValue(field.name, value[props.valueField]);

                if (props.storeWholeObjectIn) {
                  setFieldValue(props.storeWholeObjectIn, value);
                }

                if (typeof props.optionLabelField === 'function') {
                  setValueLabel(
                    props
                      .optionLabelField(value)
                      .map(field => value?.[field])
                      .join(' '),
                  );
                  return;
                }

                setValueLabel(value[props.optionLabelField]);

                if (Boolean(props.onChange)) {
                  props.onChange(value);
                }
              }}
              value={field.value}
              variant="input"
            />
            <ErrorMessage {...field} />
          </FormField>
        );
      }}
    </Field>
  );
};

SearchableSelectField.defaultProps = {
  valueField: 'value',
};

export const SegmentedControlField = props => (
  <Field name={props.name}>
    {({ field, form }) => (
      <FormField {...props}>
        <SegmentedControl
          {...props}
          values={typeof field.value === 'object' ? field.value : [field.value]}
          onChange={v => form.setFieldValue(field.name, v)}
        />
        <ErrorMessage {...field} />
      </FormField>
    )}
  </Field>
);

SegmentedControlField.defaultProps = {
  values: [],
};

const CheckboxContainer = styled.span`
  display: inline-block;
  display: flex;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacing(0.25)};
  color: ${({ checked, theme }) =>
    checked ? theme.primary : theme.textLighten};
  position: relative;
  padding-right: ${({ theme }) => theme.spacing(0.5)};

  &:before {
    position: absolute;
    top: 8px;
    left: 4px;
    right: 8px;
    bottom: 4px;
    z-index: -1;
    display: block;
    content: '';
  }

  &:hover:before {
    transition: background-color 100ms ease;
    background-color: #f4f2ef;
  }
`;

const CheckboxLabel = styled.label`
  cursor: pointer;
  display: flex;
  user-select: none;
  color: currentColor;
  align-items: center;
  font-size: 14px;

  [type='checkbox'] {
    display: none;
  }

  svg {
    height: 24px;
  }
`;

export const Checkbox = ({
  checked,
  label,
  onChange,
  dark,
  checkboxColor,
  name,
  iconSize,
}) => (
  <CheckboxLabel checked={checked} dark={dark}>
    <input type="checkbox" checked={checked} onChange={onChange} name={name} />

    <CheckboxContainer checked={checked} checkboxColor={checkboxColor}>
      {checked && <MdCheckBox size={iconSize} />}
      {!checked && <MdCheckBoxOutlineBlank size={iconSize} />}
    </CheckboxContainer>
    {label}
  </CheckboxLabel>
);

export const CheckboxField = ({ onChange, name, ...props }) => (
  <Field name={name} type="checkbox">
    {({ field, form }) => {
      const handleChange = () => {
        form.setFieldTouched(field.name, true);
        form.setFieldValue(field.name, !field.checked);
        if (Boolean(onChange)) {
          onChange(!field.checked);
        }
      };

      return (
        <FormField>
          <Checkbox {...props} {...field} onChange={handleChange} />
          <ErrorMessage name={field.name} />
        </FormField>
      );
    }}
  </Field>
);

export const FormValues = ({ values, onChangeValues, setFieldValue }) => {
  useDeepCompareEffect(() => {
    onChangeValues(values, setFieldValue);
  }, [{ values }]);

  return null;
};

export const SelectTagsField = ({
  name,
  valueFieldId,
  selectProps,
  valueFieldLabel,
  maximumTagsNumber,
  tagsListAlignment,
  ...props
}) => {
  return (
    <Field name={name}>
      {({ field, form }) => {
        const handleSelectTag = tag => {
          if (
            field.value?.length < maximumTagsNumber ||
            !Boolean(maximumTagsNumber)
          ) {
            form.setFieldValue(name, [...field.value, tag]);
          }
        };

        const handleDeleteTag = targetFieldId => {
          form.setFieldValue(
            name,
            field.value.filter(tag => tag[valueFieldId] !== targetFieldId),
          );
        };

        return (
          <div style={{ position: 'relative' }}>
            <SearchableSelect
              variant="input"
              onChange={handleSelectTag}
              valueFieldId={valueFieldId}
              ommitedFieldIds={field?.value?.map(tag => tag[valueFieldId])}
              {...selectProps}
            />
            <TagsListWrapper tagsListAlignment={tagsListAlignment}>
              <TagsList>
                {(field?.value ?? []).map((value, index) => (
                  <TagItem key={index} alignX="space-between">
                    <span>{value?.[valueFieldLabel]}</span>
                    <ButtonReset
                      type="button"
                      onClick={() => handleDeleteTag(value?.[valueFieldId])}
                    >
                      <MdClear color="white" />
                    </ButtonReset>
                  </TagItem>
                ))}
              </TagsList>
            </TagsListWrapper>
          </div>
        );
      }}
    </Field>
  );
};

export const SelectTagsFieldManualSettings = ({ name, options }) => {
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        const handleSelectTag = tag => {
          form.setFieldValue(name, [...field.value, tag?.value]);
        };

        const handleDeleteTag = targetFieldId => {
          form.setFieldValue(
            name,
            field.value.filter(tag => tag !== targetFieldId),
          );
        };

        const unselectedOptions = options.filter(
          option => !field.value.includes(option.value),
        );

        const hasError = Boolean(meta.touched) && Boolean(meta.error);

        return (
          <FormField>
            <div style={{ position: 'relative' }}>
              <SearchableSelectManualSettings
                variant="input"
                onChange={handleSelectTag}
                options={unselectedOptions}
                renderItem={({ label }) => label}
                hasError={hasError}
              />

              <TagsList>
                {(field?.value ?? []).map((value, index) => (
                  <TagItem key={index} alignX="space-between">
                    <span>{getLabelFromOptionsList(value)}</span>
                    <ButtonReset
                      type="button"
                      onClick={() => handleDeleteTag(value)}
                    >
                      <MdClear color="white" />
                    </ButtonReset>
                  </TagItem>
                ))}
              </TagsList>
            </div>
            <ErrorMessage name={field.name} />
          </FormField>
        );
      }}
    </Field>
  );
};
