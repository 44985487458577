import React from 'react';
import styled from 'styled-components';
import { TextField, Form, CheckboxField } from './Forms';
import { Button, Buttons } from '@tymate/elise/ui';
import { Formik } from 'formik';
import { ERRORS } from '@tymate/elise/utils';
import * as Yup from 'yup';
import TermModal from './TermModal';
import { Link, Route } from 'react-router-dom';
import { history } from 'index';
import { useLocation } from 'react-use';

const Title = styled.h2`
  color: ${({ theme }) => theme.text};
  font-size: 1.5rem;
  line-height: 1.25;
  text-align: center;
  max-width: 10em;
  margin: 0;
  margin-right: auto;
  margin-left: auto;
`;

const Explanation = styled.p`
  color: ${({ theme }) => theme.textLight};
  line-height: 1.5;
  text-align: center;
  margin: 0;
  margin-top: ${({ theme }) => theme.spacing()};
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const SignUpForm = ({ onSubmit, match, terms, app }) => {
  const location = useLocation();
  const lastVersionGtu = terms?.filter(({ kind }) => kind === 'gtu')?.[0];
  const lastVersionGtc = terms?.filter(({ kind }) => kind === 'gtc')?.[0];

  const SignupFormSchema = Yup.object().shape({
    firstName: Yup.string().required(ERRORS.REQUIRED),
    lastName: Yup.string().required(ERRORS.REQUIRED),
    password: Yup.string().required(ERRORS.REQUIRED),
    gtu:
      app === 'franchise' || app === 'extranet'
        ? Yup.bool().oneOf([true], ERRORS.REQUIRED)
        : Yup.mixed(),
    gtc: Yup.bool().oneOf([true], ERRORS.REQUIRED),
  });

  return (
    <>
      <Formik
        validationSchema={SignupFormSchema}
        initialValues={{
          firstName: '',
          lastName: '',
          phoneNumber: '',
          password: '',
          gtu: false,
          gtc: false,
        }}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, dirty, isValid, errors }) => (
          <Form autoComplete="off">
            <Title>Bienvenue sur la plateforme ELISE !</Title>
            <Explanation>
              Validez les informations et créez un mot de passe personnalisé
              pour activer votre compte.
            </Explanation>

            <TextField name="firstName" label="Prénom" autoComplete="off" />
            <TextField name="lastName" label="Nom" autoComplete="off" />
            <TextField
              name="password"
              label="Mot de passe"
              type="password"
              autoComplete="off"
            />

            <TextField
              name="phoneNumber"
              label="Numéro de téléphone"
              autoComplete="off"
            />

            {(app === 'franchise' || app === 'extranet') && (
              <CheckboxField
                name="gtu"
                label={
                  <span>
                    J‘accepte les{' '}
                    <Link
                      to={{
                        pathname:
                          '/callback/invitations/conditions-generales-de-vente',
                        search: location.search,
                      }}
                    >
                      conditions générales de vente
                    </Link>
                  </span>
                }
              />
            )}

            <CheckboxField
              name="gtc"
              label={
                <span>
                  J’accepte les{' '}
                  <Link
                    to={{
                      pathname:
                        '/callback/invitations/conditions-generales-d-utilisation',
                      search: location.search,
                    }}
                  >
                    conditions générales d'utilisation
                  </Link>
                </span>
              }
            />

            <Buttons hasMarginTop>
              <Button
                variant="primary"
                size="full"
                isLoading={isSubmitting}
                disabled={!(isValid && dirty)}
              >
                Activer mon compte
              </Button>
            </Buttons>
          </Form>
        )}
      </Formik>

      <Route
        path={`${match.url}/conditions-generales-de-vente`}
        render={() => (
          <TermModal
            term={lastVersionGtc}
            onRequestClose={() =>
              history.push({ search: location.search, pathname: match.url })
            }
          />
        )}
      />

      <Route
        path={`${match.url}/conditions-generales-d-utilisation`}
        render={() => (
          <TermModal
            term={lastVersionGtu}
            onRequestClose={() =>
              history.push({ search: location.search, pathname: match.url })
            }
          />
        )}
      />
    </>
  );
};

export default SignUpForm;
