import React from 'react';
import { TextField, Form } from '@tymate/elise/components/Forms';
import { Button, Buttons } from '@tymate/elise/ui';
import Modal from '@tymate/elise/components/Modal';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ERRORS } from '@tymate/elise/utils';
import LoadingButton from '@tymate/elise/components/Button';

const CollaboratorForm = ({
  initialValues,
  onSubmit,
  onRequestClose,
  isEditing,
}) => (
  <Modal
    title={isEditing ? 'Modifier ce collaborateur' : 'Ajouter un collaborateur'}
    isOpen
    onRequestClose={onRequestClose}
  >
    <Formik
      onSubmit={onSubmit}
      validationSchema={Yup.object().shape({
        email: Yup.string().email(ERRORS.EMAIL).required(ERRORS.REQUIRED),
      })}
      initialValues={initialValues}
    >
      {({ isSubmitting }) => (
        <Form autoComplete="off">
          <TextField label="Nom" name="lastName" />
          <TextField label="Prénom" name="firstName" />
          <TextField label="Adresse email" name="email" />
          {isEditing && <TextField label="Adresse email" name="email" />}
          <Buttons alignment="right">
            <Button variant="phantom" type="button" onClick={onRequestClose}>
              Annuler
            </Button>
            <LoadingButton variant="primary" isLoading={isSubmitting}>
              Valider
            </LoadingButton>
          </Buttons>
        </Form>
      )}
    </Formik>
  </Modal>
);

CollaboratorForm.defaultProps = {
  initialValues: { lastName: '', firstName: '', email: '' },
};

export default CollaboratorForm;
