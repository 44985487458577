import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import styled, { css } from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import { PopoverItem, PopoverMenu, PopoverItemButton } from '../ui/popover';
import { Dropdown } from '@tymate/margaret';
import { MdKeyboardArrowDown } from 'react-icons/md';
import Tooltip from './Tooltip';
import Truncate from 'react-truncate';

const Label = styled.label`
  display: block;
  color: inherit;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  font-weight: inherit;

  ${({ disabled }) =>
    Boolean(disabled) &&
    `
        opacity: 0.5;
      `}
`;

const Trigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.separator};
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  font-size: 1rem;
  padding: ${({ theme }) => theme.spacing()}
    ${({ theme }) => theme.spacing(1.5)};
  width: 100%;
  line-height: 1;

  ${({ hasError, theme }) =>
    hasError &&
    `
        border-color: ${theme.error};
      `}

  ${({ variant, theme }) =>
    variant === 'input' &&
    css`
      background-color: #fff;
      padding: ${({ theme }) => theme.spacing(0.5)}
        ${({ theme }) => theme.spacing()};
      border-radius: 8px;
      color: inherit;
      font-size: 1rem;
      width: 100%;
      height: 48px;

      &:not([disabled]):focus,
      &:not([disabled]):active {
        border-color: #000;
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px white inset;
        color: inherit;
      }

      &:disabled {
        background-color: #eeeeee;
        cursor: not-allowed;
      }
    `}
  
    ${({ hasFixedWidth }) =>
    hasFixedWidth &&
    `
        width: 300px;
        max-width: 100%;
      `}
  
    ${({ disabled, theme }) =>
    Boolean(disabled) &&
    `
        background-color: ${theme.disabled};
        color: #6d6d6d;
      `}
      
    ${({ isBare }) =>
    isBare &&
    `
        padding: 0;
      `}
  
    svg {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }
`;

const SearchableSelectManualSettings = forwardRef(
  (
    {
      renderItem,
      renderTrigger,
      onChange,
      selectedItem,
      label,
      shouldStayOpenOnChange,
      dropdownWrapperStyle,
      disabled,
      variant,
      hasFixedWidth,
      value,
      hasError,
      options = [],
    },
    ref,
  ) => {
    const dropdownRef = useRef();
    const scrollbarRef = useRef();
    const [isTruncated, setIsTruncated] = useState(false);

    useImperativeHandle(ref, () => ({
      close: () => {
        if (!dropdownRef.current) {
          return;
        }

        dropdownRef.current.close();
      },
      open: () => {
        if (!dropdownRef.current) {
          return;
        }

        dropdownRef.current.open();
      },
    }));

    useEffect(
      () => {
        if (!shouldStayOpenOnChange) {
          dropdownRef.current.close();
        }
      },
      // eslint-disable-next-line
      [selectedItem],
    );

    return (
      <>
        {Boolean(label) && (
          <Label onClick={() => dropdownRef.current.open()}>{label}</Label>
        )}

        <Dropdown
          disabled={disabled}
          ref={dropdownRef}
          style={{ width: '100%' }}
          wrapperStyle={dropdownWrapperStyle}
          containerStyle={{ width: '100%' }}
          trigger={
            <Trigger
              disabled={disabled}
              variant={variant}
              hasFixedWidth={hasFixedWidth}
              hasError={hasError}
            >
              <Tooltip disabled={!isTruncated} tip={renderTrigger(value)}>
                <div style={{ width: '100%' }}>
                  <Truncate onTruncate={value => setIsTruncated(value)}>
                    {renderTrigger(value)}
                  </Truncate>
                </div>
              </Tooltip>
              <MdKeyboardArrowDown size={24} />
            </Trigger>
          }
          shouldCloseDropdownOnTriggerClick
        >
          <PopoverMenu>
            <Scrollbars ref={scrollbarRef} autoHeight autoHeightMax={200}>
              {options.map((option, index) => (
                <PopoverItem key={`${option}${index}`}>
                  <PopoverItemButton
                    type="button"
                    onClick={() => {
                      onChange(option);

                      if (!shouldStayOpenOnChange) {
                        dropdownRef.current.close();
                      }
                    }}
                    isActive={
                      Boolean(selectedItem) &&
                      Boolean(option) &&
                      ((Boolean(option.value) &&
                        option.value === selectedItem.value) ||
                        (Boolean(option.id) && option.id === selectedItem.id))
                    }
                  >
                    {renderItem(option)}
                  </PopoverItemButton>
                </PopoverItem>
              ))}
            </Scrollbars>
          </PopoverMenu>
        </Dropdown>
      </>
    );
  },
);

SearchableSelectManualSettings.defaultProps = {
  renderItem: input => <pre>{JSON.stringify(input, null, 2)}</pre>,
  renderTrigger: input => <span>{JSON.stringify(input, null, 2)}</span>,
  ommitedFieldIds: [],
};

export default SearchableSelectManualSettings;
