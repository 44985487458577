import React, { useState, useMemo } from 'react';
import { useDebounce, useDeepCompareEffect } from 'react-use';
import { get } from 'lodash';
import Search from '@tymate/elise/components/Search';
import { useSearchParams } from '../../hooks';

const LocationAwareSearch = ({
  placeholder,
  variant,
  searchParamName = 'search',
  omitSearchParams = [],
  ...props
}) => {
  const [isFirstRun, setIsFirstRun] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultValue = useMemo(() => get(searchParams, searchParamName, ''), [
    searchParamName,
    searchParams,
  ]);
  const [value, setValue] = useState(defaultValue);
  const [debouncedValue, setDebouncedValue] = useState(defaultValue);

  useDebounce(
    () => {
      if (value !== debouncedValue) {
        setDebouncedValue(value);
      }
    },
    500,
    [value],
  );

  useDeepCompareEffect(() => {
    setSearchParams({ replace: true, [searchParamName]: debouncedValue });
  }, [{ debouncedValue }]);

  useDeepCompareEffect(() => {
    if (searchParamName && !isFirstRun) {
      setValue('');
      setSearchParams({ replace: true, omitSearchParams });
    }
    if (isFirstRun) {
      setIsFirstRun(false);
    }
  }, [{ searchParamName }]);

  return (
    <Search
      value={value}
      placeholder={placeholder}
      onChange={e => setValue(e.target.value)}
      onClear={() => setValue('')}
      variant={variant}
      {...props}
    />
  );
};

export default LocationAwareSearch;
