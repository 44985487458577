import { useLocation, useHistory } from 'react-router-dom';
import { omit, pickBy } from 'lodash';
import qs from 'qs';
import { camelizeKeys } from 'humps';
import queryString from 'query-string';

const useSearchParams = () => {
  const location = useLocation();
  const history = useHistory();
  let searchParams = {};

  const setSearchParams = ({
    replace = false,
    omitSearchParams = [],
    ...params
  }) => {
    const query = queryString.parse(location.search);
    const newSearch = queryString.stringify(
      pickBy({ ...omit(query, omitSearchParams), ...params }),
    );
    const newUrl = `${location?.pathname}${newSearch ? '?' + newSearch : ''}`;

    if (replace) {
      history.replace(newUrl);
    } else {
      history.push(newUrl);
    }
  };

  try {
    searchParams = camelizeKeys(qs.parse(location.search.replace('?', '')));
  } catch (err) {}

  return [searchParams, setSearchParams];
};

export default useSearchParams;
