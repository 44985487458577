import React from 'react';
import { TextField, Form } from './Forms';
import { Button } from '@tymate/elise/components';
import { Buttons, TextLink } from '@tymate/elise/ui';
import { Error } from '@tymate/elise/ui/forms';
import { ERRORS } from '@tymate/elise/utils';
import { Formik } from 'formik';
import * as Yup from 'yup';

const LoginForm = ({ onSubmit }) => {
  const LoginFormSchema = Yup.object().shape({
    username: Yup.string().email(ERRORS.EMAIL).required(ERRORS.REQUIRED),
    password: Yup.string().required(ERRORS.REQUIRED),
  });

  return (
    <Formik
      validationSchema={LoginFormSchema}
      initialValues={{ username: '', password: '' }}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, status }) => (
        <Form>
          <TextField name="username" label="Adresse email" autocompleteOn />
          <TextField
            name="password"
            label="Mot de passe"
            type="password"
            autocompleteOn
          />

          <TextLink to="/mot-de-passe-oublie">Mot de passe oublié ?</TextLink>

          {status === 'invalid_grant' && (
            <Error>
              L’adresse email et le mot de passe que vous avez entrés ne
              correspondent pas à ceux présents dans nos fichiers. Veuillez
              vérifier et réessayer.
            </Error>
          )}

          <Buttons hasMarginTop>
            <Button variant="primary" size="full" isLoading={isSubmitting}>
              Connexion
            </Button>
          </Buttons>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
