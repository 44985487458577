import * as Sentry from '@sentry/react';

export const initSentry = (
  sentryDsn,
  sentryEnvironement,
  apiBase,
  history,
  release,
) => {
  if (!sentryDsn) {
    return;
  }

  const currentServerRegex = new RegExp(
    `^https?://${window.location.hostname}/`,
  );

  const apiRegex = new RegExp(`^https?://${window.location.hostname}/api`);

  return Sentry.init({
    dsn: sentryDsn,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Sentry.Replay({
        interceptConsole: true,
        networkDetailAllowUrls: [window.location.origin, apiBase],
      }),
    ],
    tracesSampleRate: 0.25,
    tracePropagationTargets: ['localhost', currentServerRegex, apiRegex],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.25,
    debug: false,
    environment: sentryEnvironement,
    release: release + '@' + process.env.npm_package_version,
  });
};
