import React, { useRef } from 'react';
import { useDeepCompareEffect } from 'react-use';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Dropdown from '@tymate/elise/components/Dropdown';
import { RawLink } from '../ui';
import {
  PopoverItem,
  PopoverItemButton,
  PopoverMenu,
} from '@tymate/elise/ui/popover';
import { FaUserCircle } from 'react-icons/fa';
import { MdBusiness, MdPowerSettingsNew } from 'react-icons/md';
import { useLocation } from 'react-use';
import { media } from '@tymate/margaret';

const HeaderAvatar = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing()};

  ${media.desktop`
    border: 1px solid ${({ theme }) => theme.separator};
    color: ${({ theme }) => theme.text}
    background-color: #ffffff;
    box-shadow: 0 1px 2px 0 ${({ theme }) => theme.separator};
    padding: ${({ theme }) => theme.spacing(0.5)}
      ${({ theme }) => theme.spacing()};
    border-radius: 20px;
  `}
`;

const Name = styled.div`
  display: none;
  margin-right: ${({ theme }) => theme.spacing()};
  white-space: nowrap;

  ${media.desktop`
    display: block;
  `}
`;

const Icon = styled.span`
  display: flex;
  margin-right: ${({ theme }) => theme.spacing(0.5)};
`;

const UserDropdown = ({ app, user, onReset, showProfile = true }) => {
  const dropdownRef = useRef();
  const location = useLocation();
  const key = location?.state?.key;

  useDeepCompareEffect(() => {
    if (!Boolean(dropdownRef.current)) {
      return;
    }

    dropdownRef.current.close();
  }, [{ key }]);

  return (
    <Dropdown
      ref={dropdownRef}
      trigger={
        <HeaderAvatar>
          <Name>
            {user?.firstName} {user?.lastName}
          </Name>
          <FaUserCircle size={28} color="#eb732c" />
        </HeaderAvatar>
      }
    >
      <PopoverMenu alignment="right">
        {app === 'franchise' && (
          <PopoverItem>
            <PopoverItemButton as={Link} to="/ma-franchise/production-sites">
              <Icon>
                <MdBusiness size={22} />
              </Icon>
              <span>Ma franchise</span>
            </PopoverItemButton>
          </PopoverItem>
        )}
        {showProfile && (
          <PopoverItem>
            <PopoverItemButton as={RawLink} to="/profil">
              <Icon>
                <FaUserCircle size={22} />
              </Icon>
              <span>Mon compte</span>
            </PopoverItemButton>
          </PopoverItem>
        )}
        <PopoverItem>
          <PopoverItemButton onClick={onReset}>
            <Icon>
              <MdPowerSettingsNew size={22} />
            </Icon>
            <span>Déconnexion</span>
          </PopoverItemButton>
        </PopoverItem>
      </PopoverMenu>
    </Dropdown>
  );
};

export default UserDropdown;
