import React from 'react';
import styled from 'styled-components';
import { Form, TextField } from '../components/Forms';
import { Formik } from 'formik';
import { Button, Buttons, TextLink } from '../ui';
import * as Yup from 'yup';
import { ERRORS } from '../utils';
import warning from '../images/warning.svg';

const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${({ theme }) => theme.spacing()};
`;

const Image = styled.img`
  height: 60px;
  width: 60px;
`;

const Title = styled.h2`
  color: ${({ theme }) => theme.text};
  font-size: 1.5rem;
  line-height: 2;
  text-align: center;
  margin: 0;
`;

const Explanation = styled.p`
  color: ${({ theme }) => theme.textLight};
  line-height: 1.5;
  text-align: center;
`;

const forgottenPasswordFormSchema = Yup.object().shape({
  email: Yup.string().email(ERRORS.EMAIL).required(ERRORS.REQUIRED),
});

const ForgottenPasswordForm = ({ onSubmit }) => (
  <Formik
    onSubmit={onSubmit}
    validationSchema={forgottenPasswordFormSchema}
    initialValues={{ email: '' }}
  >
    {({ isSubmitting, status }) => (
      <Form autoComplete="off">
        {Boolean(status) && (
          <ImageWrapper>
            <Image src={warning} alt="" />{' '}
          </ImageWrapper>
        )}
        <Title>{Boolean(status) ? 'Oups !' : 'Problèmes de connexion'}</Title>
        <Explanation>
          {Boolean(status)
            ? status
            : 'Entrez votre adresse e-mail et nous vous enverrons un lien pour récupérer votre compte.'}
        </Explanation>
        <TextField name="email" placeholder="Entrez votre adresse email..." />

        <Buttons hasMarginTop>
          <Button variant="primary" isLoading={isSubmitting} size="full">
            Envoyer un lien de connexion
          </Button>
        </Buttons>
        <TextLink
          style={{
            display: 'inline-block',
            position: 'absolute',
            textAlign: 'center',
            bottom: 8,
            left: '50%',
            transform: 'translateX(-50%)',
          }}
          to="/connexion"
        >
          Retour à l'écran de connexion
        </TextLink>
      </Form>
    )}
  </Formik>
);

export default ForgottenPasswordForm;
