import React, { useState, useRef } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { PopoverItem, PopoverItemButton, PopoverMenu } from '../ui/popover';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { get, find } from 'lodash';
import { useDebounce, useDeepCompareEffect } from 'react-use';
import { Input } from '../ui/forms';
import { Dropdown } from '@tymate/elise/components';
import { Stack } from '@tymate/margaret';
import Spinner from './Spinner';

const Label = styled.label`
  display: block;
  color: inherit;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  font-weight: inherit;
`;

const SubLabel = styled(Label)`
  color: ${({ theme }) => theme.textLighten};
`;

const NothingFound = styled.div`
  display: grid;
  place-content: center;
  color: ${({ theme }) => theme.textLighten};
  padding: ${({ theme }) => theme.spacing()}
    ${({ theme }) => theme.spacing(0.125)};
`;

const Trigger = styled.div`
  display: grid;
  place-content: center;
  padding: 0;

  width: 48px;
  height: 48px;
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.separator};
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;

  ${({ disabled, theme }) =>
    Boolean(disabled) &&
    css`
      background-color: ${theme.disabled};
      color: #6d6d6d;
    `}
`;

const CompactSelect = ({
  label,
  placeholder,
  onChange,
  defaultValue,
  options = [],
  disabled,
  value,
  isSearchable,
  onUpdateSearch,
  hasError,
  showOptionalLabel = false,
  shouldShowDash,
  initialValueLabel,
  isLoading,
}) => {
  const searchInputRef = useRef();
  const theme = useTheme();
  const dropdownRef = useRef();
  const valueLabel = get(
    find(options, opt => opt.value === defaultValue),
    'label',
  );

  const initialValue = {
    value: defaultValue,
    label: valueLabel || initialValueLabel,
  };
  const [selectedValue, setSelectedValue] = useState(
    Boolean(defaultValue) ? initialValue : null,
  );
  const [search, setSearch] = useState('');
  const [shouldShowSearch, setShouldShowSearch] = useState(false);

  useDebounce(
    () => {
      if (!isSearchable) {
        return;
      }

      onUpdateSearch(search);
    },
    100,
    [search],
  );

  useDeepCompareEffect(() => {
    const selectedOption = find(options, option => option.value === value);

    if (!selectedOption) {
      return setSelectedValue(null);
    }

    setSelectedValue(selectedOption);
  }, [{ options, value }]);

  useDeepCompareEffect(() => {
    if (!shouldShowSearch) {
      setSearch('');
      return;
    }

    searchInputRef.current.focus();
  }, [{ shouldShowSearch }]);

  useDeepCompareEffect(() => {
    if (!selectedValue || !onChange) {
      return;
    }

    onChange(selectedValue);

    if (dropdownRef.current) {
      dropdownRef.current.close();
    }
  }, [{ selectedValue }]);

  return (
    <>
      <Stack alignY="center" alignX="space-between">
        {Boolean(label) && <Label>{label}</Label>}
        {Boolean(showOptionalLabel) && <SubLabel>{'(optionnel)'}</SubLabel>}
      </Stack>

      <Dropdown
        disabled={disabled}
        ref={dropdownRef}
        shouldShowDash={shouldShowDash}
        onToggle={isOpen => setShouldShowSearch(isSearchable && isOpen)}
        shouldCloseDropdownOnTriggerClick
        trigger={
          <Trigger
            disabled={disabled}
            isBare={shouldShowSearch}
            hasError={hasError}
          >
            <MdKeyboardArrowDown size={24} />
          </Trigger>
        }
      >
        <PopoverMenu size="medium">
          {shouldShowSearch && (
            <Stack
              gutterSize={0.25}
              alignY="center"
              style={{ borderBottom: `1px solid ${theme.separator}` }}
              paddingLeft={0.25}
            >
              <Input
                value={search}
                onChange={e => setSearch(e.target.value)}
                onKeyUp={e => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                  }
                }}
                placeholder={placeholder}
                ref={searchInputRef}
                isBare
              />
              {isLoading && <Spinner size={8} />}
            </Stack>
          )}
          {options.map(({ value, label }, index) => (
            <PopoverItem key={index}>
              <PopoverItemButton
                type="button"
                onClick={() => setSelectedValue({ value, label })}
                isActive={value === selectedValue?.value}
              >
                {label}
              </PopoverItemButton>
            </PopoverItem>
          ))}

          {options.length === 0 && (
            <NothingFound>Aucun résultat à votre recherche.</NothingFound>
          )}
        </PopoverMenu>
      </Dropdown>
    </>
  );
};

CompactSelect.defaultProps = {
  placeholder: '',
  options: [],
  onUpdateSearch: () => {},
};

export default CompactSelect;
