import React from 'react';
import { useRef } from 'react';
import { useDeepCompareEffect, useIntersection } from 'react-use';
import Loading from './Loading';

const InfiniteScrollSensor = ({ onLoadMore, isFetching }) => {
  const intersectionRef = useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 1,
  });
  const { isIntersecting } = intersection || {};

  useDeepCompareEffect(() => {
    if (isFetching) {
      return;
    }

    if (isIntersecting && onLoadMore && !isFetching) {
      onLoadMore();
    }
  }, [{ isFetching, isIntersecting, onLoadMore }]);

  return (
    <div>
      <div ref={intersectionRef} />
      {isFetching && isIntersecting && <Loading />}
    </div>
  );
};

export default InfiniteScrollSensor;
