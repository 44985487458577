import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  background-size: contain;
  background-repeat: repeat;
  background-position: center center;
  width: 100%;
  min-height: 100vh;
  padding: ${({ theme }) => theme.spacing(2)};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ variant, theme }) =>
    variant === 'withImage' &&
    css`
      padding-top: ${theme.spacing()};
    `}
`;

const Logo = styled.img`
  max-width: 300px;
  margin-top: ${({ theme }) => theme.spacing(3)};
  margin-bottom: ${({ theme }) => theme.spacing(3)};

  ${({ variant, theme }) =>
    variant === 'withImage' &&
    css`
      margin-top: 0;
      margin-bottom: ${theme.spacing()};
    `}
`;

const Card = styled.div`
  border-radius: 8px;
  overflow: hidden;
  background-color: #ffffff;
  box-shadow: 0 4px 16px 8px rgba(0, 0, 0, 0.12);
  padding: ${({ theme }) => theme.spacing(2.5)}
    ${({ theme }) => theme.spacing(2)};
  width: 100%;
  max-width: 400px;
  position: relative;

  ${({ variant, theme }) =>
    variant === 'withImage' &&
    css`
      position: relative;
      padding-left: calc(250px + ${theme.spacing(2)});
      max-width: 650px;
    `};
`;

const SideImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 250px;
  background-size: cover;
  background-position: center center;
  borer-radius: 8px 0 0 8px;
`;

const AuthLayout = ({ backgroundImage, logo, children, sideImage }) => (
  <Wrapper
    style={{ backgroundImage: `url(${backgroundImage})` }}
    variant={Boolean(sideImage) && 'withImage'}
  >
    <Logo src={logo} alt="Elise" variant={Boolean(sideImage) && 'withImage'} />

    <Card variant={Boolean(sideImage) && 'withImage'}>
      {Boolean(sideImage) && (
        <SideImage style={{ backgroundImage: `url(${sideImage})` }} />
      )}
      {children}
    </Card>
  </Wrapper>
);

export default AuthLayout;
