import React from 'react';
import styled, { css } from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { ButtonReset } from './';
import { MdAdd, MdRemove } from 'react-icons/md';

export const Label = styled.label`
  display: block;
  color: inherit;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  font-weight: inherit;
`;

export const SubLabel = styled(Label)`
  color: ${({ theme }) => theme.textLighten};
  white-space: nowrap;
`;

export const Input = styled.input.attrs(({ autocompleteOn = false }) => ({
  autocomplete: autocompleteOn ? 'on' : 'off',
}))`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)};
  border: 1px solid ${({ theme }) => theme.separator};
  flex: 1;
  border-radius: 8px;
  transition: border-color 100ms ease;
  width: 100%;
  background-color: white;

  ${({ type }) =>
    type === 'password' &&
    css`
      padding-right: 44px;
    `}

  &:not([disabled]):focus,
  &:not([disabled]):active {
    outline: none;
    border: 1px solid ${({ theme }) => theme.primaryLight};
  }

  &:disabled {
    background-color: ${({ theme }) => theme.disabled};
    color: #6d6d6d;
  }

  ::placeholder {
    color: ${({ theme }) => theme.textLight};
  }

  ${({ variant, theme }) =>
    variant === 'input' &&
    css`
      border-radius: 4px 0 0 4px;
      border-right: none;
      border: 1px solid ${theme.primary};

      &:not([disabled]):focus,
      &:not([disabled]):active {
        outline: none;
        background-color: #feeec9;
        border: 1px solid ${theme.primary};
      }
    `}

  ${({ variant, theme }) =>
    variant === 'search' &&
    css`
      border-radius: 8px; !important
      border: 1px solid ${theme.separator}; !important
    `}

  

  ${({ hasError, theme }) =>
    hasError &&
    css`
      &,
      &:hover,
      &:active {
        border: 1px solid ${theme.error};
      }
    `}

  ${({ hasFixedWidth }) =>
    hasFixedWidth &&
    `
      width: 300px;
      max-width: 100%;
    `}

  
  ${({ isBare }) =>
    isBare &&
    css`
      &,
      &:not([disabled]):focus,
      &:not([disabled]):active {
        background-color: transparent;
        outline: none;
        border: 0;
      }
    `}
    

  ${({ isSelectAndSearch }) =>
    isSelectAndSearch &&
    `
      border-radius: 0px 8px 8px 0px;
      border-left: 1px solid transparent;
    `}

  ${({ variant }) =>
    variant === 'justShowText' &&
    css`
      &:disabled {
        background-color: transparent;
        color: black;
        outline: none;
        border: 0;
      }
    `}

  ${({ textAlign }) =>
    textAlign &&
    css`
      text-align: ${textAlign};
    `}


  ${({ variant }) =>
    variant === 'error' &&
    css`
      background-color: transparent;
      color: ${({ theme }) => theme.error};
      font-weight: bold;
      border: 0;
      &:not([disabled]):focus,
      &:not([disabled]):active {
        outline: none;
        border: 0;
      }
      padding: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) =>
      theme.spacing(0.5)} ${({ theme }) => theme.spacing(0.5)} 0};
      margin: 0 0 0  ${({ theme }) => theme.spacing(0.25)}
    `}

    ${({ variant }) =>
    variant === 'warning' &&
    css`
    
      background-color: ${({ theme }) => theme.white};
      color: ${({ theme }) => theme.warning};
      font-weight: bold;
      border: 0;
      &:not([disabled]):focus,
      &:not([disabled]):active {
        outline: none;
        border: 0;
      }
      padding: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) =>
      theme.spacing(0.5)} ${({ theme }) => theme.spacing(0.5)} 0};
      margin: 0 0 0  ${({ theme }) => theme.spacing(0.25)}
    `}

    ${({ variant }) =>
    variant === 'warningFromError' &&
    css`
      background-color: transparent;
      color: ${({ theme }) => theme.warning};
      font-weight: bold;
      border: 0;
      &:not([disabled]):focus,
      &:not([disabled]):active {
        outline: none;
        border: 0;
      }
      padding: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) =>
      theme.spacing(0.5)} ${({ theme }) => theme.spacing(0.5)} 0};
      margin: 0 0 0  ${({ theme }) => theme.spacing(0.25)}
    `}
`;

export const NumberInput = props => (
  <div style={{ position: 'relative', maxWidth: 140 }}>
    <Input
      {...props}
      onChange={e => props.onChange(e.target.value)}
      type="number"
      step="any"
    />
    <TextFieldStepper
      type="button"
      onClick={() =>
        props.onChange(
          Boolean(props.max)
            ? Math.min(props.max, (Number(props.value) || 0) + 1)
            : (Number(props.value) || 0) + 1,
        )
      }
    >
      <MdAdd />
    </TextFieldStepper>

    <TextFieldStepper
      variant="bottom"
      type="button"
      onClick={() =>
        props.onChange(Math.max((Number(props.value) || 0) - 1, 0))
      }
    >
      <MdRemove />
    </TextFieldStepper>
  </div>
);

export const Textarea = styled(TextareaAutosize)`
  padding: ${({ theme }) => theme.spacing(0.875)}
    ${({ theme }) => theme.spacing()};
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 6px;
  color: ${({ theme }) => theme.text};
  font-size: 1em;
  font-family: inherit;
  font-weight: 400;
  outline: none;
  width: 100%;
  resize: none;
  background-color: white;

  &:focus,
  &:active {
    border-color: ${({ theme }) => theme.darkSeparator};
  }

  ::placeholder {
    color: ${({ theme }) => theme.textLight};
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
    color: inherit;
  }

  &:disabled {
    background-color: rgba(0, 40, 100, 0.04);
    cursor: not-allowed;
  }

  ${({ hasError }) =>
    hasError &&
    css`
      &,
      &:active,
      &:focus {
        border-color: ${({ theme }) => theme.error};
      }
    `}
`;

export const TextareaCustom = styled.textarea`
  padding: ${({ theme }) => theme.spacing(0.875)}
    ${({ theme }) => theme.spacing()};
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: 6px;
  color: ${({ theme }) => theme.text};
  font-size: 1em;
  font-family: inherit;
  font-weight: 400;
  outline: none;
  width: 100%;
  resize: none;
  background-color: white;
  overflow: hidden;

  &:focus,
  &:active {
    border-color: ${({ theme }) => theme.darkSeparator};
  }

  ::placeholder {
    color: ${({ theme }) => theme.textLight};
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 30px white inset;
    color: inherit;
  }

  &:disabled {
    background-color: rgba(0, 40, 100, 0.04);
    cursor: not-allowed;
  }

  ${({ hasError }) =>
    hasError &&
    css`
      &,
      &:active,
      &:focus {
        border-color: ${({ theme }) => theme.error};
      }
    `}
`;

export const SearchInput = styled(Input)`
  padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(2)};
  border-radius: 8px;

  ${({ isSelectAndSearch }) =>
    isSelectAndSearch &&
    `
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;

  `}
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  + * {
    margin-top: ${({ theme }) => theme.spacing(1.5)};

    ${({ noMargin }) =>
      noMargin &&
      css`
        margin-top: 0;
      `}
  }

  ${({ noFullWidth }) =>
    noFullWidth &&
    css`
      width: auto;
    `}
`;

export const FormLabel = styled.label`
  margin-bottom: ${({ theme }) => theme.spacing(0.25)};
  text-align: left;
  color: ${({ theme }) => theme.textLighten};
  width: 100%;
  font-size: 0.875rem;
  white-space: nowrap;
`;

export const ToggleFormLabel = styled(FormLabel)`
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  user-select: none;

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
    `}
`;

export const Error = styled.div`
  font-size: 12px;
  border: 1px solid ${({ theme }) => theme.error};
  color: ${({ theme }) => theme.error};
  padding: ${({ theme }) => theme.spacing(0.25)}
    ${({ theme }) => theme.spacing(0.5)};
  margin-top: ${({ theme }) => theme.spacing()}
  border-radius: 8px;

  ${({ variant }) =>
    variant === 'info' &&
    css`
      color: rgb(41, 204, 122);
      border-color: rgb(41, 204, 122);
    `}
`;

export const TextFieldStepper = styled(ButtonReset)`
  position: absolute;
  background-color: ${({ theme }) => theme.primary};
  bottom: 50%;
  top: 0;
  right: 0;
  width: 30px;
  border-radius: 0 8px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: #fff;
    font-size: 1.25rem;
  }

  ${({ variant }) =>
    variant === 'bottom' &&
    css`
      bottom: 0;
      top: 50%;
      border-radius: 0 0 8px 0;
    `}
`;

export const FormError = styled.span`
  color: ${({ theme }) => theme.error};
  font-size: 0.75em;
`;
