import React, {
  useRef,
  useState,
  useEffect,
  useReducer,
  forwardRef,
  useImperativeHandle,
} from 'react';
import styled, { css } from 'styled-components';
import { Scrollbars } from 'react-custom-scrollbars';
import { PopoverItem, PopoverMenu, PopoverItemButton } from '../ui/popover';
import { Input } from '../ui/forms';
import { Dropdown } from '@tymate/margaret';
import { useDebounce } from 'react-use';
import queryString from 'query-string';
import {
  pickBy,
  get,
  keys,
  orderBy,
  values,
  flatten,
  debounce,
  includes,
} from 'lodash';
import { getPageAndRestFromQuery } from '../utils/pagination';
import { MdKeyboardArrowDown } from 'react-icons/md';
import Tooltip from './Tooltip';
import Truncate from 'react-truncate';

const Label = styled.label`
  display: block;
  color: inherit;
  margin-bottom: ${({ theme }) => theme.spacing(0.5)};
  font-weight: inherit;

  ${({ disabled }) =>
    Boolean(disabled) &&
    `
      opacity: 0.5;
    `}
`;

const Trigger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.separator};
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  font-size: 1rem;
  padding: ${({ theme }) => theme.spacing()}
    ${({ theme }) => theme.spacing(1.5)};
  width: 100%;
  line-height: 1;

  ${({ hasError, theme }) =>
    hasError &&
    `
      border-color: ${theme.error};
    `}

  ${({ variant, theme }) =>
    variant === 'input' &&
    css`
      background-color: #fff;
      padding: ${({ theme }) => theme.spacing(0.5)}
        ${({ theme }) => theme.spacing()};
      border-radius: 8px;
      color: inherit;
      font-size: 1rem;
      width: 100%;
      height: 48px;

      &:not([disabled]):focus,
      &:not([disabled]):active {
        border-color: #000;
      }

      &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 30px white inset;
        color: inherit;
      }

      &:disabled {
        background-color: #eeeeee;
        cursor: not-allowed;
      }
    `}

  ${({ hasFixedWidth }) =>
    hasFixedWidth &&
    `
      width: 300px;
      max-width: 100%;
    `}

  ${({ disabled, theme }) =>
    Boolean(disabled) &&
    `
      background-color: ${theme.disabled};
      color: #6d6d6d;
    `}
    
  ${({ isBare }) =>
    isBare &&
    `
      padding: 0;
    `}

  svg {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }
`;

const initialState = { byId: {}, pagination: {} };

const reducer = entityName => (state, action) => {
  const { page, query } = getPageAndRestFromQuery(action.payload.meta.query);

  switch (action.type) {
    case 'REQUEST':
      return {
        [query]: {
          ...get(state, [query]),
          statusesByPage: {
            ...get(state, [query, 'statusesByPage']),
            [page]: 'PENDING',
          },
        },
      };

    case 'SUCCESS':
      return {
        [query]: {
          ...get(state, [query]),
          pagination: {
            ...get(state, [query, 'pagination']),
            currentPage: Number(
              get(action, 'payload.headers.paginationCurrentPage'),
            ),
            count: Number(get(action, 'payload.headers.paginationTotalCount')),
            pages: Number(get(action, 'payload.headers.paginationTotalPages')),
          },
          data: {
            ...get(state, [query, 'data']),
            [page]: action.payload.data,
          },
          statusesByPage: {
            ...get(state, [query, 'statusesByPage']),
            [page]: 'DONE',
          },
        },
      };

    case 'ERROR':
      return {
        [query]: {
          ...get(state, [query]),
          statusesByPage: {
            ...get(state, [query, 'statusesByPage']),
            [page]: 'STALLED',
          },
        },
      };

    default:
      throw new Error();
  }
};

const getData = (inputQuery, data) => {
  const { query } = getPageAndRestFromQuery(inputQuery);

  const pagination = get(data, [query]);
  const statusesObject = get(pagination, 'statusesByPage', {});

  const output = flatten(
    orderBy(keys(get(pagination, 'data', {})).map(Number)).map(key =>
      get(pagination, ['data', key]),
    ),
  );

  const isFetching = values(statusesObject).indexOf('PENDING') > -1;
  const hasError = values(statusesObject).indexOf('STALLED') > -1;

  return {
    data: output,
    isFetching,
    hasError,
    meta: get(pagination, 'pagination', {}),
  };
};

const SearchableSelect = forwardRef(
  (
    {
      renderItem,
      renderTrigger,
      renderExtraHeader,
      addedParams,
      requestFn,
      entityName,
      onChange,
      selectedItem,
      label,
      shouldStayOpenOnChange,
      dropdownWrapperStyle,
      disabled,
      defaultParams,
      variant,
      hasFixedWidth,
      initialValueLabel,
      value,
      searchQueryName,
      hasError,
      valueFieldId,
      ommitedFieldIds,
    },
    ref,
  ) => {
    const dropdownRef = useRef();
    const searchRef = useRef();
    const scrollbarRef = useRef();
    const [search, setSearch] = useState('');
    const [debouncedSearch, setDebouncedSearch] = useState('');
    const [
      informativeDropdownStatus,
      setInformativeDropdownStatus,
    ] = useState();
    const [rawData, dispatch] = useReducer(reducer(entityName), initialState);
    const [page, setPage] = useState(1);
    const [isAboutToReachBottom, setIsAboutToReachBottom] = useState();
    const [isTruncated, setIsTruncated] = useState(false);

    useImperativeHandle(ref, () => ({
      close: () => {
        if (!dropdownRef.current) {
          return;
        }

        dropdownRef.current.close();
      },
      open: () => {
        if (!dropdownRef.current) {
          return;
        }

        dropdownRef.current.open();
      },
    }));

    const params = { [searchQueryName]: debouncedSearch };
    const aggregateQuery = queryString.stringify(
      pickBy({
        'page[size]': 20,
        'page[number]': page,
        ...defaultParams,
        ...params,
        ...addedParams,
      }),
    );
    const { data: unprocessedData, isFetching, meta } = getData(
      aggregateQuery,
      rawData,
    );

    const data = unprocessedData.filter(element => {
      if (!valueFieldId || ommitedFieldIds.length === 0) {
        return true;
      }

      return !includes(ommitedFieldIds, element[valueFieldId]);
    });

    const handleCheckScrollStatus = debounce(() => {
      const scrollbar = scrollbarRef.current;
      const CUSHION = 100;

      if (!scrollbar) {
        return;
      }

      const isAboutToReachBottom =
        scrollbar.getClientHeight() + scrollbar.getScrollTop() + CUSHION >=
        scrollbar.getScrollHeight();

      setIsAboutToReachBottom(isAboutToReachBottom);
    }, 500);

    const getAndSetData = async () => {
      const query = `${aggregateQuery}`;
      const meta = { query };

      dispatch({ type: 'REQUEST', payload: { meta } });

      try {
        const result = await requestFn({
          params: queryString.parse(aggregateQuery),
        });
        dispatch({
          type: 'SUCCESS',
          payload: { meta, ...result },
        });

        setTimeout(() => handleCheckScrollStatus(), 50);
      } catch (err) {
        dispatch({ type: 'ERROR', payload: { meta } });
      }
    };

    useDebounce(
      () => {
        setPage(1);
        setDebouncedSearch(search);
      },
      300,
      [search],
    );

    useEffect(
      () => {
        if (!informativeDropdownStatus) {
          return;
        }

        getAndSetData();
      },
      // eslint-disable-next-line
      [aggregateQuery, informativeDropdownStatus],
    );

    useEffect(() => {
      if (!informativeDropdownStatus) {
        return;
      }

      if (informativeDropdownStatus === false) {
        return;
      }

      searchRef.current.focus();
      handleCheckScrollStatus();
    }, [handleCheckScrollStatus, informativeDropdownStatus]);

    useEffect(() => {
      if (
        meta.currentPage &&
        meta.pages &&
        meta.currentPage + 1 <= meta.pages &&
        isAboutToReachBottom &&
        !isFetching
      ) {
        setPage(meta.currentPage + 1);
      }
      // eslint-disable-next-line
    }, [isAboutToReachBottom]);

    useEffect(
      () => {
        if (!shouldStayOpenOnChange) {
          dropdownRef.current.close();
        }
      },
      // eslint-disable-next-line
      [selectedItem],
    );

    return (
      <>
        {Boolean(label) && (
          <Label onClick={() => dropdownRef.current.open()}>{label}</Label>
        )}

        <Dropdown
          disabled={disabled}
          ref={dropdownRef}
          style={{ width: '100%' }}
          wrapperStyle={dropdownWrapperStyle}
          containerStyle={{ width: '100%' }}
          trigger={
            <Trigger
              disabled={disabled}
              variant={variant}
              hasFixedWidth={hasFixedWidth}
              isBare={informativeDropdownStatus}
              hasError={hasError}
            >
              {informativeDropdownStatus ? (
                <Input
                  ref={searchRef}
                  value={search}
                  onKeyUp={e => e.preventDefault()}
                  onChange={e => setSearch(e.target.value)}
                  isBare
                />
              ) : (
                <>
                  <Tooltip disabled={!isTruncated} tip={renderTrigger(value)}>
                    <div style={{ width: '100%' }}>
                      <Truncate onTruncate={value => setIsTruncated(value)}>
                        {renderTrigger(value)}
                      </Truncate>
                    </div>
                  </Tooltip>
                  <MdKeyboardArrowDown size={24} />
                </>
              )}
            </Trigger>
          }
          onToggle={setInformativeDropdownStatus}
          shouldCloseDropdownOnTriggerClick
        >
          {Boolean(renderExtraHeader) && renderExtraHeader()}

          {!isFetching && data.length === 0 && (
            <PopoverMenu>
              <div style={{ padding: 8 }}>
                Aucun résultat à votre recherche.
              </div>
            </PopoverMenu>
          )}

          {data.length > 0 && (
            <PopoverMenu>
              <Scrollbars
                ref={scrollbarRef}
                autoHeight
                autoHeightMax={200}
                onScrollStop={handleCheckScrollStatus}
              >
                {data.filter(Boolean).map((option, index) => (
                  <PopoverItem key={`${option}${index}`}>
                    <PopoverItemButton
                      type="button"
                      onClick={() => {
                        onChange(option);

                        if (!shouldStayOpenOnChange) {
                          dropdownRef.current.close();
                        }
                      }}
                      isActive={
                        Boolean(selectedItem) &&
                        Boolean(option) &&
                        ((Boolean(option.value) &&
                          option.value === selectedItem.value) ||
                          (Boolean(option.id) && option.id === selectedItem.id))
                      }
                    >
                      {renderItem(option)}
                    </PopoverItemButton>
                  </PopoverItem>
                ))}
              </Scrollbars>
            </PopoverMenu>
          )}
        </Dropdown>
      </>
    );
  },
);

SearchableSelect.defaultProps = {
  renderItem: input => <pre>{JSON.stringify(input, null, 2)}</pre>,
  renderTrigger: input => <span>{JSON.stringify(input, null, 2)}</span>,
  ommitedFieldIds: [],
};

export default SearchableSelect;
