import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { MdSearch, MdHighlightOff } from 'react-icons/md';
import { ButtonReset } from '../ui';
import { SearchInput } from '../ui/forms';
import useAutoFocus from '../hooks/useAutoFocus';
import { useDebounce } from 'react-use';
import { useHistory } from 'react-router';

const CancelableInputContainer = styled.div`
  position: relative;
  display: inline-block;

  > ${ButtonReset} {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: ${({ theme }) => theme.spacing(0.5)};
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.separator};

    &:hover {
      cursor: pointer;
    }
  }

  ${props =>
    (props.full || props.size === 'full') &&
    css`
      width: 100%;
    `};
`;

const SearchIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${({ theme }) => theme.spacing(0.25)};
  padding: ${({ theme }) => theme.spacing(0.25)};
  line-height: 0.8;
  color: ${({ theme }) => theme.separator};
`;

const Search = ({
  onChange,
  value,
  onClear,
  secondary,
  full,
  size,
  isFetching,
  isSelectAndSearch,
  placeholder = 'Rechercher',
  isRedirectScan = 'false',
  redirectUrl,
}) => {
  const refInput = useAutoFocus();
  const [charTimeDiff, setCharTimeDiff] = useState([]);
  const [inputTimeStamp, setTimeStamp] = useState(3600);
  const history = useHistory();

  const handleKeyDown = e => {
    if (isRedirectScan) {
      setCharTimeDiff([...charTimeDiff, e.timeStamp]);
      setTimeStamp(e.timeStamp);
    }
  };

  const handleRedirectScan = () => {
    const sumTimeChar =
      inputTimeStamp -
      charTimeDiff?.reduce((a, b) => a + b) / charTimeDiff.length;

    if (sumTimeChar < 50 && redirectUrl) {
      history.push(redirectUrl);
    }

    return sumTimeChar;
  };

  useDebounce(
    () => {
      if (isRedirectScan && charTimeDiff.length > 0) {
        handleRedirectScan();
      }

      setCharTimeDiff([]);
    },
    1000,
    [charTimeDiff],
  );

  return (
    <CancelableInputContainer full={full} size={size}>
      <SearchIcon>
        {isFetching ? (
          {
            /* <MDSpinner
              style={{ marginRight: 8 }}
              singleColor="currentColor"
              size={16}
            /> */
          }
        ) : (
          <MdSearch size="20" />
        )}
      </SearchIcon>

      <SearchInput
        ref={refInput}
        onKeyDown={e => handleKeyDown(e)}
        type="text"
        {...{ onChange, value, secondary }}
        placeholder={placeholder}
        isSelectAndSearch={isSelectAndSearch}
        style={full || size === 'full' ? { width: '100%' } : {}}
      />

      <ButtonReset
        phantom
        style={{ opacity: Boolean(value) ? 1 : 0 }}
        onClick={onClear}
      >
        <MdHighlightOff size="20" />
      </ButtonReset>
    </CancelableInputContainer>
  );
};
export default Search;
