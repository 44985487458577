import * as React from 'react';

const IcLogo = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="64"
    viewBox="0 0 38 64"
    fill="none"
    {...props}
    style={{ ...props.style, color: props.color, fontSize: props.size }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.3414 25.8178C35.1132 16.2872 27.2233 8.53333 17.7539 8.53333C8.24862 8.53333 0.53795 16.0078 0.173841 25.5589L0.168193 25.5582L0.166687 25.8231C0.214883 26.1181 0.452853 26.3292 0.737513 26.3459V26.3523C3.16767 26.3523 5.50105 27.2632 7.30842 28.9167L7.41121 29.0191C7.52982 29.1101 7.65483 29.1556 7.78398 29.1556C7.99522 29.1556 8.19026 29.0422 8.31489 28.8326L8.39961 28.5259C10.2428 22.6056 15.6189 18.6276 21.7794 18.6276C26.9703 18.6276 31.715 21.5006 34.1602 26.1256L34.2751 26.336C34.3967 26.4797 34.5631 26.5589 34.742 26.5589C35.0413 26.5589 35.2947 26.3788 35.3459 26.06L35.3414 25.8178ZM34.2464 30.806C35.3326 30.1711 36.7068 30.9412 36.91 32.1262C37.0447 32.9099 37.1227 33.734 37.1227 34.5385C37.1227 36.0384 36.8735 37.5126 36.4004 38.8946C34.7703 44.7412 29.6052 49.3446 27.9792 61.9897V64H22.6041V58.6065H20.7309V64H15.4032V62.0245C14.6903 52.906 8.57491 45.7536 7.00427 39.1023C6.5624 37.8897 6.28501 36.6039 6.20935 35.2682C6.19166 34.9413 6.30721 34.5283 6.47282 34.2486C6.59552 34.041 6.81156 33.8526 7.01368 33.728C7.43824 33.4686 7.95539 33.4226 8.43941 33.4868C8.9957 33.5608 9.33332 33.8737 9.41574 34.4441C9.48801 36.3291 10.1478 38.3001 11.1125 39.9245C11.2013 40.0755 11.2894 40.2284 11.3853 40.3756C11.4862 40.5296 11.595 40.6784 11.7011 40.8279C11.8024 40.9656 11.9014 41.1034 12.0068 41.2371C14.2598 44.1103 17.7477 45.9628 21.6752 45.9628C28.4689 45.9628 33.9754 40.4386 33.9754 33.6257C33.9754 32.9092 33.8993 32.2353 33.7849 31.5301C33.7273 31.2734 34.0555 30.9174 34.2464 30.806ZM21.8428 29.8667C24.198 29.8667 26.1069 31.7774 26.1069 34.1337C26.1069 36.4893 24.198 38.4 21.8428 38.4C19.4872 38.4 17.5786 36.4893 17.5786 34.1337C17.5786 31.7774 19.4872 29.8667 21.8428 29.8667Z"
      fill="#CAC9C8"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.22638 16.6539L1.1495 16.9066C1.11897 17.203 1.29158 17.4712 1.5633 17.5638L1.85668 17.6901C2.31312 17.861 2.29668 17.7401 2.29668 17.7401C5.32034 12.3714 11.1776 8.53006 17.7567 8.53006C27.2347 8.53006 35.1183 16.5208 35.3467 26.0601C37.6339 16.8005 32.2574 7.43797 23.1186 4.91183C13.944 2.37507 4.09213 7.53206 1.22638 16.6539L1.2211 16.652L1.22638 16.6539Z"
      fill="#D3D2D1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.3467 26.06C40.0096 17.7901 37.3943 7.33916 29.2089 2.4949C20.9909 -2.36784 10.5131 0.0820107 5.32658 8.06639L5.32166 8.06375L5.18574 8.28855C5.07708 8.56503 5.17476 8.86792 5.4129 9.0286L5.41025 9.03388C5.59236 9.14138 5.63935 9.25 5.85669 9.25C10.1667 5.5 16.7448 3.16662 23.1167 4.91C32.2975 7.42171 37.6444 16.855 35.3467 26.06Z"
      fill="#E2E1E0"
    />
  </svg>
);

export default IcLogo;
