import { useHistory } from 'react-router-dom';
import { useEffectOnce } from 'react-use';

const getLocationId = ({ pathname, search, hash }) => {
  return pathname + (search ? '?' + search : '') + (hash ? '#' + hash : '');
};

const useLocationBlocker = () => {
  const history = useHistory();
  useEffectOnce(() => {
    history.block(
      (location, action) =>
        action !== 'PUSH' ||
        getLocationId(location) !== getLocationId(history.location),
    );
  });
};

export default useLocationBlocker;
