import React, { useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import PropTypes from 'prop-types';
import { flatMap, merge, pickBy } from 'lodash';
import InfiniteScrollSensor from '@tymate/elise/components/InfiniteScrollSensor';
import { Select } from '@tymate/elise/components';

const SelectSearchQuery = ({
  queryFn,
  queryKey,
  valueKey,
  labelKey,
  searchKey,
  optionalKeys,
  params,
  enabled,
  onChange,
  formatLabel,
  omittedOptions,
  ...props
}) => {
  const [search, setSearch] = useState('');
  const [value, setValueLabel] = useState(props.initialValueLabel);

  const query = async ({ pageParam }) => {
    const currentPage = pageParam?.currentPage || 0;
    const totalPages = pageParam?.totalPages || 1;

    if (Boolean(currentPage) && currentPage >= totalPages) {
      return;
    }

    const { data, headers } = await queryFn({
      params: pickBy(
        merge(params, {
          'page[number]': (currentPage || 0) + 1,
          [searchKey]: search,
        }),
      ),
    });

    const nextPageParam = {
      currentPage: Number(headers.paginationCurrentPage || 1),
      totalPages: Number(headers.paginationTotalPages || 1),
    };

    return { data, pageParam: nextPageParam };
  };

  const { data, fetchNextPage, isFetching } = useInfiniteQuery(
    [...queryKey, search],
    query,
    {
      getNextPageParam: ({ pageParam } = {}) => {
        if (pageParam?.currentPage < pageParam?.totalPages) {
          return pageParam;
        }
      },
      keepPreviousData: true,
      enabled,
    },
  );

  const options = flatMap(data?.pages, ({ data } = {}) =>
    (data || [])
      .filter(Boolean)
      .filter(
        item =>
          (omittedOptions ?? []).filter(x => x.value === item.id).length === 0,
      )
      .map(element => {
        let optionalKeyValues = {};

        if (optionalKeys?.length > 0) {
          optionalKeys.forEach(
            key => (optionalKeyValues[key] = element?.[key]),
          );
        }

        return {
          value: element?.[valueKey],
          label: formatLabel ? formatLabel(element) : element?.[labelKey],
          ...optionalKeyValues,
        };
      }),
  );

  return (
    <Select
      isSearchable
      onUpdateSearch={setSearch}
      options={options}
      initialValueLabel={value}
      setValueLabel={setValueLabel}
      onChange={option => {
        setValueLabel(option?.label);
        onChange(option);
      }}
      variant="input"
      size="auto"
      showOptionalLabel={props.showOptionalLabel}
      hasCompactDropList={props.hasCompactDropList}
      hasFixedWidth={props.hasFixedWidth}
    >
      <InfiniteScrollSensor
        onLoadMore={fetchNextPage}
        isFetching={isFetching}
      />
      <div style={{ paddingBottom: 1 }} />
    </Select>
  );
};

SelectSearchQuery.propTypes = {
  name: PropTypes.string.isRequired,
  query: PropTypes.func.isRequired,
  queryKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  labelKey: PropTypes.array.isRequired,
  searchKey: PropTypes.string,
};

SelectSearchQuery.defaultProps = {
  valueKey: 'id',
  labelKey: 'name',
  searchKey: 'filter[name]',
  optionalKeys: [],
  onChange: () => {},
};

export default SelectSearchQuery;
