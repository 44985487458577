import { capitalize, camelCase, pickBy, identity } from 'lodash';
import queryString from 'query-string';

export const crumbNames = {
  // Misc
  editer: 'Éditer',

  // Paramètres
  parametres: 'Paramètres',
  monProfil: 'Mon profil',

  // Points de collecte
  'points-de-collecte': 'Clients',

  // Ordres de mission
  'ordres-de-mission': 'Ordres de mission',

  // Weighing
  pesees: 'Toutes les pesées',

  // Extranet
  'mes-documents': 'Mes documents',
};

export const getPathSlug = url =>
  url.slice(1).split('/').map(camelCase).join('_');

export const getCrumbName = (crumb, url, customTranslations = {}) => {
  const pathSlug = getPathSlug(url);
  const customTranslation = customTranslations[pathSlug];
  const pathTranslation = crumbNames[pathSlug];
  const crumbTranslation = crumbNames[camelCase(crumb)];

  // If full path has specific translation, use it.
  // If not, return the crumb translation.
  // If it does not exist, return the crumb slug.
  return customTranslation
    ? customTranslation
    : pathTranslation
    ? pathTranslation
    : crumbTranslation
    ? crumbTranslation
    : capitalize(crumb);
};

export const getURLWithParam = (url = '', search, param = {}) => {
  // pickBy removes null values from params object. It permits to
  // remove a key from URL by setting key: null.
  const params = pickBy(
    {
      ...queryString.parse(search),
      ...param,
    },
    identity,
  );

  const query = queryString.stringify(params);

  if (!Boolean(query)) {
    return url;
  }

  return `${url}?${query}`;
};

export const getPageFromQuery = query => queryString.parse(query).page || 1;

export const getQueryWithoutPage = input => {
  const { page: omit, ...query } = queryString.parse(input);

  return `?${queryString.stringify(query)}`;
};
