import { createGlobalStyle } from 'styled-components';

export const DatePickerStyle = createGlobalStyle`
.react-date-picker.error 
.react-date-picker__inputGroup {
  border-top: 1px solid ${({ theme }) => theme.error};
  border-left: 1px solid ${({ theme }) => theme.error};
  border-bottom: 1px solid ${({ theme }) => theme.error};
}

.react-date-picker {
  width: 100%;

  .react-date-picker__wrapper {
    border: 0;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
  }

  .react-date-picker__clear-button {
    border-top: 1px solid ${({ theme }) => theme.separator};
    border-bottom: 1px solid ${({ theme }) => theme.separator};
    background-color: #fff;
    svg {
      color: ${({ theme }) => theme.separator}
    }
  }
  
  .react-date-picker__inputGroup {
    border-top: 1px solid ${({ theme }) => theme.separator};
    border-left: 1px solid ${({ theme }) => theme.separator};
    border-bottom: 1px solid ${({ theme }) => theme.separator};
    border-radius: 8px 0 0 8px;
    padding: ${({ theme }) => theme.spacing(0.5)}
    ${({ theme }) => theme.spacing(0.75)} ;
    ${({ error, theme }) =>
      error &&
      `border-top: 1px solid ${theme.error};
       border-left: 1px solid ${theme.error};
       border-bottom: 1px solid ${theme.error};`};
  }

  .react-date-picker__calendar-button {
    background-color: ${({ theme }) => theme.primary};
    color: #fff;
    border-radius: 0 8px 8px 0;
    padding-right: ${({ theme }) => theme.spacing(0.5)};
    padding-left: ${({ theme }) => theme.spacing(0.5)};
  }

  .react-date-picker__calendar--open {
    z-index: 567;
  }

  .react-date-picker__inputGroup {
    &:disabled {
      border: 1px solid ${({ theme }) => theme.separator};
      background-color: ${({ theme }) => theme.disabled};
      color: #6d6d6d;
    }
  }
}
`;
