import React from 'react';
import styled, { css } from 'styled-components';

const Tip = styled.span`
  opacity: 0;
  display: none;
  position: absolute;
  top: calc(100% + ${({ theme }) => theme.spacing(0.5)});
  left: 50%;
  transform: translate3d(-50%, -4px, 0);
  background-color: #111;
  color: #fff;
  font-size: 0.875rem;
  line-height: 1.3;
  padding: ${({ theme }) => theme.spacing(0.5)};
  border-radius: 4px;
  transition: transform 75ms ease;
  text-transform: none;
  white-space: normal;
  width: 160px;
  text-align: center;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    border: 4px solid transparent;
    z-index: 1;
    border-bottom-color: #111;
  }

  :disabled & {
    display: none;
  }

  ${({ variant }) =>
    variant === 'auto' &&
    css`
      width: max-content;
      padding: ${({ theme }) => theme.spacing(0.5)};
    `}

  ${({ variant }) =>
    variant === 'big' &&
    css`
      width: 300px;
    `}

  ${({ variant, theme }) =>
    variant === 'orange' &&
    css`
      background-color: ${theme.primaryLight};
      &:before {
        border-bottom-color: ${theme.primaryLight};
      }
    `}

    ${props =>
    props.top &&
    css`
      bottom: calc(100% + ${({ theme }) => theme.spacing(0.5)});
      top: auto;

      &:before {
        bottom: -8px;
        top: auto;
        transform: rotate(180deg);
      }
    `};

  ${props =>
    props.left &&
    css`
      right: -4px;
      left: auto;
      transform: none;

      &:before {
        left: auto;
        right: 4px;
        transform: none;
      }
    `};
`;

const Wrapper = styled.span`
  position: relative;
  display: block;
  flex: 1;

  &:hover ${Tip} {
    opacity: 1;
    display: block;
  }

  svg {
    display: block;
  }
`;

const Content = styled.span``;

const Tooltip = ({
  children,
  tip,
  name,
  left,
  variant,
  disabled,
  tipStyle,
  top,
}) => (
  <Wrapper>
    <Content aria-labelledby={disabled ? null : name}>{children}</Content>
    {!disabled && (
      <Tip
        id={name}
        left={left}
        top={top}
        variant={variant}
        style={{ ...tipStyle }}
      >
        {tip}
      </Tip>
    )}
  </Wrapper>
);

export default Tooltip;
