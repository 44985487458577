import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { NavLink, useLocation } from 'react-router-dom';
import { capitalize, last, get } from 'lodash';
import { List, Container } from '../ui';
import { useAppState } from '../hooks';
import { crumbNames } from '../utils/routes';
import { Stack } from '@tymate/margaret';

const Wrapper = styled(Container)`
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  font-size: 18px;
`;

const Separator = styled.span`
  color: ${({ theme }) => theme.separator};
  padding: 0 ${({ theme }) => theme.spacing(0.625)}
    ${({ theme }) => theme.spacing(0.125)};
  font-size: 1.25rem;
  line-height: 1.25;
`;

const BreadcrumbContent = styled(List)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 ${({ theme }) => theme.spacing()};
  border-radius: ${({ theme }) => theme.borderRadius};
`;

const Crumb = styled.li`
  display: flex;
  line-height: ${({ theme }) => theme.spacing(1.5)};
  align-items: center;
`;

const CrumbLink = styled(NavLink)`
  color: ${({ theme }) => theme.textLight};
  text-decoration: none;
  align-items: center;
  line-height: 1.5;
`;

const CrumbLast = styled(CrumbLink)`
  display: flex;
  text-decoration: none;
  color: ${({ theme }) => theme.textLighten};

  ${({ variant }) =>
    variant !== 'main' &&
    css`
      box-shadow: none;
      padding-left: 0;
      padding-right: 0;
    `}
`;

const Breadcrumb = ({ lastCrumbLabel, children, ignoredPaths = [] }) => {
  const { pathname } = useLocation();
  const { routesNames } = useAppState();

  const urlChunks = pathname.split('/').filter(Boolean);

  const getPathTranslation = path => {
    const chunks = path.split('/');
    const chunk = last(chunks);

    const routeCustomName = get(routesNames, `/${path}`);
    const routeName = get(crumbNames, `${path}`);
    const crumbName = get(crumbNames, `${chunk}`);

    if (Boolean(routeCustomName)) {
      return routeCustomName;
    }

    if (Boolean(routeName)) {
      return routeName;
    }

    if (Boolean(crumbName)) {
      return crumbName;
    }

    return capitalize(chunk);
  };

  const chunks = urlChunks.reduce((acc, _, index) => {
    const path = urlChunks.slice(0, index + 1).join('/');

    for (const regex of ignoredPaths) {
      if (Boolean(path.match(regex))) {
        return acc;
      }
    }

    return [
      ...acc,
      {
        path: `/${path}`,
        text: getPathTranslation(path),
      },
    ];
  }, []);

  const pageTitle =
    typeof last(chunks)?.text === 'object' || !Boolean(last(chunks)?.text)
      ? 'Elise'
      : `${last(chunks)?.text} – Elise`;

  useEffect(() => {
    document.title = pageTitle;
  }, [pageTitle]);

  return (
    <Wrapper aria-label="Breadcrumb" variant="breadcrumb" as="nav">
      <Stack alignY="center" alignX="space-between" size="full">
        <BreadcrumbContent>
          {chunks.map(({ path, text, icon }, index) => {
            const isFirst = index === 0;
            const isLast = chunks.length - 1 === index;

            return (
              <Crumb isLast={isLast} key={index}>
                {index !== 0 && <Separator>/</Separator>}

                {isFirst || !isLast ? (
                  <CrumbLink to={path} isLast={isLast}>
                    {icon}
                    {text}
                  </CrumbLink>
                ) : (
                  <CrumbLast as="span" variant={index === 0 && 'main'}>
                    {icon}
                    {lastCrumbLabel || text}
                  </CrumbLast>
                )}
              </Crumb>
            );
          })}
        </BreadcrumbContent>
        {children}
      </Stack>
    </Wrapper>
  );
};

export default Breadcrumb;
