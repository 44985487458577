import { useContext } from 'react';
import { AuthContext } from 'contexts';
import { AppContext } from '@tymate/elise/contexts';

export const useAppState = () => {
  const context = useContext(AppContext);

  if (context === undefined) {
    throw new Error(`useAppState must be used within a AppProvider`);
  }

  return context;
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
};
