import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { media, ButtonReset } from '../ui';
import { Stack } from '@tymate/margaret';

export const Page = styled.div`
  background-color: ${({ theme }) => theme.background};
  min-height: 100vh;
`;

export const Content = styled.main`
  background-color: ${({ theme }) => theme.background};
  padding-top: 80px;
`;

export const HeaderWrapper = styled(Stack).attrs({
  alignY: 'center',
  gutterSize: 2,
})`
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.12);
  padding: ${({ theme }) => theme.spacing()};
`;

export const FooterWrapper = styled(Stack).attrs({
  alignY: 'center',
  gutterSize: 2,
})`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 80px;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 -4px 12px 0 rgba(0, 0, 0, 0.12);
  padding: ${({ theme }) => theme.spacing()};
`;

export const HeaderLogo = styled.img`
  display: block;
  height: calc(60px - ${({ theme }) => theme.spacing(2)});
  width: auto;

  ${media.tablet`
    width: 100px;
    height: auto;
  `}
`;

export const HeaderLogoLink = styled(NavLink)`
  display: block;
  position: relative;
  z-index: 2;
  padding: ${({ theme }) => theme.spacing()};

  ${media.tablet`
    padding-top: 0;
    padding-bottom: 0;
  `}
`;

export const MainNav = styled(Stack).attrs({ as: 'ul' })`
  list-style-type: none;
  position: relative;
  background-color: transparent;
  padding-left: 0;
  flex: 1;
`;

export const MainNavBackdrop = styled(ButtonReset)`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: transparent;
  width: 100%;

  ${props =>
    props.isShown &&
    css`
      background-color: rgba(0, 0, 0, 0.4);
      display: block;
      transition: background-color 100ms ease;
    `}

  ${media.tablet`
    display: none;
  `}
`;

export const MainNavItem = styled.li`
  margin-left: ${({ theme }) => theme.spacing()};
`;

export const MainNavItemLink = styled(NavLink)`
  text-decoration: none;
  font-weight: 500;
  display: flex;
  white-space: nowrap;
  padding-top: ${({ theme }) => theme.spacing(0.75)};
  padding-bottom: ${({ theme }) => theme.spacing(0.75)};
  position: relative;
  color: ${({ theme }) => theme.text};

  &.active {
    color: ${({ theme }) => theme.primary};
  }

  ${media.tablet`
    color: ${({ theme }) => theme.textLight};
    padding: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) =>
    theme.spacing()};

    &:hover {
      color: ${({ theme }) => theme.primaryLight};
    }

    &.active {
      background-color: ${({ theme }) => theme.primaryLight};
      color: #ffffff;
      border-radius: 20px;
      
    }
  `}
`;

export const MobileHeader = styled(Stack).attrs({
  alignX: 'space-between',
  alignY: 'center',
  size: 'full',
})`
  position: absolute;
  height: 60px;
  background-color: #fff;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
`;

export const MobileTrigger = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
`;
