import React from 'react';
import { useFormContext } from 'react-hook-form';
import {
  Input,
  FormField,
  FormLabel,
  FormError,
  TextFieldStepper,
} from '@tymate/elise/ui/forms';
import { RiErrorWarningLine } from 'react-icons/ri';
import { BiCheckCircle } from 'react-icons/bi';
import { Stack } from '@tymate/margaret';
import styled, { css } from 'styled-components';
import { theme } from '../../ui';
import { MdAdd, MdRemove } from 'react-icons/md';

const WarningAndAlertStack = styled(Stack).attrs({
  alignY: 'center',
  gutterSize: 0.125,
})`
  border-radius: 8px;
  ${({ variant }) =>
    variant === 'error' &&
    css`
      background-color: ${({ theme }) => theme.errorLight};
      color: ${({ theme }) => theme.error};
      border: 1px solid ${({ theme }) => theme.error};
    `}

  ${({ variant }) =>
    variant === 'warning' &&
    css`
      background-color: ${({ theme }) => theme.white};
      color: ${({ theme }) => theme.warning};
      border: 1px solid ${({ theme }) => theme.warning};
    `}

  ${({ variant }) =>
    variant === 'warningFromError' &&
    css`
      background-color: ${({ theme }) => theme.warningLight};
      color: ${({ theme }) => theme.warning};
      border: 1px solid ${({ theme }) => theme.warning};
    `}
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;

  ${({ type }) =>
    type === 'number' &&
    css`
      width: auto;
    `}
`;

const TextField = ({
  label,
  name,
  disabled,
  placeholder,
  variant,
  noMargin,
  textAlign,
  type,
  onChange = () => {},
  max,
  min = 0,
  ...props
}) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const field = watch(name);
  const error = errors[name];

  return (
    <FormField noMargin={noMargin} variant={variant}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <WarningAndAlertStack variant={variant}>
        {variant === 'error' && (
          <div style={{ paddingLeft: theme.spacing(0.5) }}>
            <RiErrorWarningLine size={24} />
          </div>
        )}
        {(variant === 'warning' || variant === 'warningFromError') && (
          <div style={{ paddingLeft: theme.spacing(0.5) }}>
            <BiCheckCircle size={24} />
          </div>
        )}
        <InputContainer type={type}>
          <Input
            id={name}
            aria-invalid={error ? 'true' : 'false'}
            type="text"
            hasError={error}
            disabled={disabled}
            {...register(name, {
              onChange: e => onChange(e.target.value),
            })}
            placeholder={placeholder}
            variant={variant}
            textAlign={textAlign}
            {...props}
          />

          {type === 'number' && (
            <>
              <TextFieldStepper
                disabled={props.disabled}
                type="button"
                onClick={() =>
                  setValue(
                    name,
                    Boolean(max)
                      ? Math.min(max, (field || 0) + 1)
                      : (field || 0) + 1,
                  )
                }
              >
                <MdAdd />
              </TextFieldStepper>

              <TextFieldStepper
                disabled={props.disabled}
                variant="bottom"
                type="button"
                onClick={() => setValue(name, Math.max((field || 0) - 1, min))}
              >
                <MdRemove />
              </TextFieldStepper>
            </>
          )}
        </InputContainer>
        {error && error.message && (
          <FormError role="alert">{error.message}</FormError>
        )}
      </WarningAndAlertStack>
    </FormField>
  );
};

export default TextField;
