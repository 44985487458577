import React from 'react';
import styled, { css } from 'styled-components';
import { MdChevronRight, MdChevronLeft } from 'react-icons/md';
import { ButtonReset, List } from '../../ui';

const Wrapper = styled(List)`
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica Neue, Helvetica, Arial, sans-serif;
  text-align: center;
  margin-top: ${({ theme }) => theme.spacing()};
`;

const Item = styled.li`
  display: inline-block;
  background-color: #ffffff;
  border: 1px solid ${({ theme }) => theme.separator};
  border-radius: ${({ theme }) => theme.spacing(0.25)};
  width: 42px;
  color: ${({ theme }) => theme.primary};

  + li {
    margin-left: ${({ theme }) => theme.spacing(0.5)};
  }

  ${props =>
    props.ellipsis &&
    css`
      padding-left: ${({ theme }) => theme.spacing()};
      padding-right: ${({ theme }) => theme.spacing()};
      height: 42px;
      padding: ${({ theme }) => theme.spacing(0.5)};
    `};
`;

const Link = styled(ButtonReset)`
  padding: ${({ theme }) => theme.spacing(0.5)};
  min-width: 2em;
  color: inherit;
  display: block;
  transition: background-color 150ms ease;
  text-decoration: none;
  white-space: nowrap;
  text-align: center;
  width: 100%;
  &:hover {
    border-radius: 4px;
  }
`;

const NonLink = styled.span`
  padding: ${({ theme }) => theme.spacing(0.5)};
  min-width: 2em;
  display: block;
  color: ${({ theme }) => theme.separator};
  white-space: nowrap;
`;

const ActivePage = styled(NonLink)`
  background-color: ${({ theme }) => theme.primary};
  border-radius: 4px;
  color: #fff;
`;

const SimplePagination = ({ meta, page, setPage }) => {
  const totalPages = Number(meta?.paginationTotalPages || 1);

  if (totalPages < 2) {
    return null;
  }

  return (
    <Wrapper>
      <Item>
        {page > 1 ? (
          <Link
            onClick={() => {
              setPage(page - 1);
            }}
          >
            <MdChevronLeft />
          </Link>
        ) : (
          <NonLink>
            <MdChevronLeft />
          </NonLink>
        )}
      </Item>
      {page !== 1 && (
        <Item>
          <Link
            onClick={() => {
              setPage(1);
            }}
          >
            1
          </Link>
        </Item>
      )}
      {page > 4 && <Item ellipsis>…</Item>}
      {page > 3 && (
        <Item>
          <Link
            onClick={() => {
              setPage(page - 2);
            }}
          >
            {page - 2}
          </Link>
        </Item>
      )}
      {page > 2 && (
        <Item>
          <Link
            onClick={() => {
              setPage(page - 1);
            }}
          >
            {page - 1}
          </Link>
        </Item>
      )}
      <Item>
        <ActivePage>{page}</ActivePage>
      </Item>
      {page + 1 < totalPages && (
        <Item>
          <Link
            onClick={() => {
              setPage(page + 1);
            }}
          >
            {page + 1}
          </Link>
        </Item>
      )}
      {page + 2 < totalPages && (
        <Item>
          <Link
            onClick={() => {
              setPage(page + 2);
            }}
          >
            {page + 2}
          </Link>
        </Item>
      )}
      {page + 3 < totalPages && <Item ellipsis>…</Item>}
      {page !== totalPages && (
        <Item>
          <Link
            onClick={() => {
              setPage(totalPages);
            }}
          >
            {totalPages}
          </Link>
        </Item>
      )}
      <Item>
        {page < totalPages ? (
          <Link
            onClick={() => {
              setPage(page + 1);
            }}
          >
            <MdChevronRight />
          </Link>
        ) : (
          <NonLink>
            <MdChevronRight />
          </NonLink>
        )}
      </Item>
    </Wrapper>
  );
};

export default SimplePagination;
